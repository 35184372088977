import React, { useState } from 'react';
import { Space,Button, Tooltip} from 'antd';
import layer from '../../../../asesst/Icons/interactivemap/icon/layer4-white.svg'
import { CompBaseMapsMobile } from '../../basemaps/mobile/basemaps.mobile';

export const LayerToolsMobile: React.FC<{ onChangeBasemap: (style: maplibregl.StyleSpecification | string) => void }> = ({ onChangeBasemap }) => {
  const [showBaseMaps, setShowBaseMaps] = useState(false);
  const handleBaseMapsButtonClick = () => {
    setShowBaseMaps(!showBaseMaps);
};

  return (
    <React.Fragment>
    <Space
      direction='vertical'
      style={{
        position: 'absolute',
        right: '15px',
        bottom: 290,
        zIndex: 100,
        transition: '0.25s ease-in-out',
      }}
    >
      <Tooltip 
        title={<p style={{color:'black'}}>Layer</p>}
        placement="top"
        color='white'
      >
        <Button
          id='end-point'
          style={{
            backgroundColor: '#142943',
            color: 'black',
            height: "50px",
            width: "50px",
            borderBottomLeftRadius:'0px',
            borderBottomRightRadius:'0px',
            borderTopLeftRadius:'12px',
            borderTopRightRadius:'12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        //   onClick={handleBasemapChange}
        onClick={handleBaseMapsButtonClick}
        >
          <img 
            src={layer}
            alt="Layer Icon" 
          />
        </Button>
      </Tooltip>
    </Space>
      {showBaseMaps && <CompBaseMapsMobile drawerOpen={true} onChangeBasemap={onChangeBasemap}/>}
      </React.Fragment>
  );
};
