import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { notification, Modal } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import { formatDate } from '../../../component/newmap/helper/date';
import { LiaCoinsSolid } from "react-icons/lia";
import useReduxUser from '../../../hoc/hooks/useUser';
import useReduxPayment from '../../../hoc/hooks/usePayment';

export const ModalWalletMethode: React.FC<any> = ({ isModalConfirmPayment, data,amount }) => {
    const [, setLoading] = useState(false);  // Loading state for fetching user data
    const { getDetailUser, dataUser } = useReduxUser();
    const { setCreatePaymentWallet } = useReduxPayment();
    const {
        setModalCloseCheckout,
        setSelectedItem,
        setModalWalletPayment,
    } = useReduxCatalog();

    const fetchGetUser = async () => {
        if (!data?.uid) return; // Ensure user exists
        setLoading(true);
        try {
            const userId = data.uid;
            await getDetailUser(userId);  // Fetch user details
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching
        }
    };

    useEffect(() => {
        if (data?.uid) {
            fetchGetUser();  // Fetch user details when user is available
        }
        // eslint-disable-next-line 
    }, [data?.uid]);  // Trigger effect when user changes

    const handleSubmit = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await setCreatePaymentWallet(data);
            if (response && response.success) {
                    handleCancel(); // Close the modal
                    setModalCloseCheckout(false);
                    setSelectedItem(null)
                    notification.success({
                        style: {
                            backgroundColor: '#333939',
                            color: 'white'  // Menjadikan teks berwarna putih
                        },
                        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Successful</p>,
                        description: <p style={{ color: 'white', fontWeight: 'bold' }}>Successfully Please check your DATA for further actions.</p>,
                        placement: 'topRight',
                    });
            }
        } catch (error) {
            // Handle errors during payment checkout
            notification.error({
                style: {
                    backgroundColor: '#333939',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        } finally {
            setLoading(false); // Set loading to false after the operation completes
            handleCancel(); // Close the modal
            // setOpenModalConfirmPayment(true); // Open payment confirmation modal
        }
    };
    const handleCancel = () => {
        setModalWalletPayment(false);

    };
    // console.log(totalPrice);

    return (
        <Modal
            onCancel={handleCancel}
            styles={{
                content: {
                    backgroundColor: '#333939'
                },
                body: {
                    backgroundColor: '#333939',
                    color: 'white'
                },
            }}
            open={isModalConfirmPayment}
            width={400}
            footer={false}
            style={{ top: '3%' }}
            cancelText="Cancel"
            closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
        >
            <div
                style={{
                    backgroundColor: 'transparent',
                    width: '350px',
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginBottom: '20px',
                    }}
                >
                    <LiaCoinsSolid size={64} style={{ color: '#48BF40', fontSize: '20px' }} />
                </div>
                <h2 style={{ fontSize: '15px', margin: '0 0 18px' }}>You will use your GeoCredit to complete the purchase order.</h2>

                {/* <div style={{
                    border: '2px solid #fff',
                    borderRadius: '8px',
                    textAlign: 'center'
                }}>
                    <h2 style={{ fontSize: '24px' }}>Current Balance</h2>
                    {dataUser?.balance < amount && (
                    <p style={{ color: 'red'}}>
                       Balance not enough
                    </p>
                )}
                    <h1
                        style={{
                            fontSize: '32px',
                            margin: '0 0 20px',
                            color: dataUser?.balance < amount ? 'red' : 'white', // Change color to red if balance is less than amount
                        }}
                    >
                        Rp {formatNumberWithDots(dataUser?.balance)}
                    </h1>
                </div> */}
                <div
                    style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        marginBottom: '20px',
                        marginTop: '20px',
                        color: '#ddd',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',

                    }}
                >
                    
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Description</strong>
                        <span style={{ textAlign: 'right' }}>{data?.description}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Date Purchase</strong>
                        <span style={{ textAlign: 'right' }}>{formatDate(data?.created_at)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Account Email</strong>
                        <span style={{ textAlign: 'right' }}>{data?.email}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Total Amount</strong>
                        <span style={{ textAlign: 'right' }}> Rp {formatNumberWithDots(amount)}</span>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <button
                     disabled={dataUser?.balance < amount} 
                           onClick={handleSubmit}
                        style={{
                            backgroundColor: dataUser?.balance < amount ? '#B0B0B0' : '#4A7FC0', // Gray out if disabled
                            color: '#fff',
                            width: '100%',
                            fontWeight: 400,
                            padding: '10px 16px',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: dataUser?.balance < amount ? 'not-allowed' : 'pointer', 
                        }}
                    >
                        Buy 
                    </button>
                </div>
            </div>
        </Modal>
    )
}