export const getEsriWorldImageryStyle = (): maplibregl.StyleSpecification => ({
    version: 8, // Ensure this matches the expected type in your typings.
    sources: {
      'esri-world-imagery': {
        type: 'raster',
        tiles: ['https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],
        tileSize: 256,
        attribution: '&copy; Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
      },
    },
    layers: [
      {
        id: 'esri-world-imagery-layer',
        type: 'raster',
        source: 'esri-world-imagery',
      },
    ],
  });
  
  export const getOSMMapStyle = (): maplibregl.StyleSpecification => ({
    version: 8, // Ensure this matches the expected type in your typings.
    sources: {
      'esri-world-imagery': {
        type: 'raster',
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      },
    },
    layers: [
      {
        id: 'esri-world-imagery-layer',
        type: 'raster',
        source: 'esri-world-imagery',
      },
    ],
  });

  export const getLightMapStyle = (): string => 
  'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM';