import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { simplify } from '@turf/turf';
import iconPolygon from '../../../asesst/Icons/interactivemap/icon/polygon.svg';
import iconPoint from '../../../asesst/Icons/interactivemap/icon/point.svg';
import { renderGeocodeResultToMap, GeoJSONPolygon, GeoJSONFeature } from '../../../component/newmap/helper/map/geocode';
import { GeocodingControl } from '@maptiler/geocoding-control/react';
import { Feature, Geometry } from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { handleDrawClick } from '../../../component/newmap/helper/map/draw.polygon';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { handleReverseGeocode } from '../../../component/newmap/helper/map/reversecode';
import { ResultCatalog } from './drawer.result.catalog';
import { enablePointCreation, removeCurrentMarker } from '../../../component/newmap/helper/map/draw.point';
import { addIsochroneLayer } from '../../../component/newmap/helper/map/isochrones';
import { clearPolygonLayer, removeGeocodeLayersAndPopups, removeIsochronesAndPopups, removeLayersAndSources } from '../../../component/newmap/helper/map/sourceLayer';

interface DrawerMenuCatalogProps {
    map: maplibregl.Map | null; // Pass the map instance as a prop
    draw: MapboxDraw
}
export const DrawerMenuCatalog: React.FC<DrawerMenuCatalogProps> = ({ map ,draw}) => {
    const {
        setCatalogGeometry,
        setCatalogLocation,
        setIsDrawActive,
        setDrawnPolygon,
        setIsochronest,
        setShowCardCatalog,
        isochrones,
        isDrawActive,
        paramsCatalogGeometry,
    } = useReduxCatalog();
    // const [draw] = useState<MapboxDraw>(initializeMapboxDraw);
    const [isPointActive, setIsPointActive] = useState(false);

    // const handleGeocodingSelect = async (result: Feature<Geometry> | undefined) => {
    //     if (result?.geometry?.type === 'Polygon' && map) {
    //         clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
    //         removeIsochronesAndPopups(map)
    //         removeLayersAndSources(map)
    //         // removeCurrentMarker();
    //         const geojson: GeoJSONFeature = {
    //             type: 'Feature',
    //             geometry: result.geometry as GeoJSONPolygon,
    //             properties: {},
    //         };
    //         renderGeocodeResultToMap(map, geojson);
    //         setCatalogGeometry(geojson.geometry);
    //         try {
    //             const locationCode = await handleReverseGeocode(geojson.geometry);
    //             setCatalogLocation(locationCode);
    //         } catch (error) {
    //             console.error('Error fetching address:', error);
    //         }
    //     }
    // };

    const handleGeocodingSelect = async (result: Feature<Geometry> | undefined) => {
        if (result && map) {
            clearPolygonLayer(map, draw); // Remove the polygon layer if it exists
            removeIsochronesAndPopups(map);
            removeLayersAndSources(map);
            setShowCardCatalog(false)
            // Simplify the polygon geometry using Turf.js before setting it
            const simplifiedGeometry = simplify(result.geometry as GeoJSONPolygon, {
                tolerance: 0.01, // Set the tolerance based on your needs
                highQuality: false, // Set to true for higher quality, but slower simplification
            });
    
            const geojson: GeoJSONFeature = {
                type: 'Feature',
                geometry: result.geometry as GeoJSONPolygon,
                properties: {},
            };
            const simplified: GeoJSONFeature = {
                type: 'Feature',
                geometry: simplifiedGeometry,
                properties: {},
            };
    
            // Render the simplified geometry on the map
            renderGeocodeResultToMap(map, geojson);
            setCatalogGeometry(simplified.geometry);
    
            try {
                const locationCode = await handleReverseGeocode(geojson.geometry);
                setCatalogLocation(locationCode);
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        }
    };

    const handleDrawPolygon = () => {
        handleDrawClick(map, draw);
        if (map && draw) {
            map.on('draw.create', handleDrawCreate);
            removeGeocodeLayersAndPopups(map);
            setIsDrawActive(true);
            setIsPointActive(false);
            removeCurrentMarker();
            removeIsochronesAndPopups(map)
            removeLayersAndSources(map)
            setShowCardCatalog(false)
        }
    };



    const handleDrawCreate = async (event: any) => {
        const polygon = event.features[0].geometry;
        setDrawnPolygon({
            type: 'Feature',
            geometry: polygon,
            properties: {},
        });
         // Remove geocoded search layer if it exists
        setIsDrawActive(false);
        setCatalogGeometry(polygon);
        // removeCurrentMarker();
        try {
            const locationCode = await handleReverseGeocode(polygon);
            setCatalogLocation(locationCode);
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    const handlePointButtonClick = () => {
        if (!map) return;
        if (isPointActive) {
            // Deactivate point mode and remove marker
            // removeCurrentMarker();
            setIsPointActive(false);
            removeIsochronesAndPopups(map)
        } else {
            setIsPointActive(true);
            setIsDrawActive(false);
            removeGeocodeLayersAndPopups(map);
            clearPolygonLayer(map,draw);
            setShowCardCatalog(false)
            removeLayersAndSources(map);
        }
    };

    const handlePointsCallback = (lngLat: maplibregl.LngLat) => {
        setIsochronest(lngLat)
    };

    useEffect(() => {
        if (map && isPointActive) {
            enablePointCreation(map, isPointActive, handlePointsCallback);  //
            addIsochroneLayer(map, isochrones)
        }
        if (isochrones) {
            setCatalogGeometry(isochrones?.features?.[0]?.geometry);
        }

        // eslint-disable-next-line
    }, [map, isPointActive, isochrones]);


    useEffect(() => {
        if (map && draw) {
            // Add event listeners for create and update events
            map.on('draw.create', handleDrawCreate);
            map.on('draw.update', handleDrawCreate);
            // Cleanup listeners on unmount
            return () => {
                map.off('draw.create', handleDrawCreate);
                map.off('draw.update', handleDrawCreate);
            };
        }
        // eslint-disable-next-line
    }, [map, draw]);


    return (
        <React.Fragment>
            <h2 style={{ color: 'white' }}>Find Insight</h2>
            <div style={{ marginBottom: '10px' }}>
                <h4 style={{ color: 'white', marginTop: '20px' }}>Select Location</h4>
                <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '10px', marginBottom: '10px' }}>
                    Choose an area by searching, dropping a pin, or drawing a custom polygon.
                </p>
                <GeocodingControl
                    onPick={handleGeocodingSelect}
                    apiKey={'IkttUJmMfqWCx0g43vGM'}
                />
            </div>

            <div style={{ marginBottom: '20px' }}>
                {/* Conditionally render either Draw or Remove button */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                    <Button
                        type="primary"
                        icon={<img src={iconPoint} alt="custom polygon icon" />}
                        style={{
                            width: '150px',
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: isPointActive ? 'red' : '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={handlePointButtonClick}
                    >
                        Point
                    </Button>
                    <Button
                        type="primary"
                        icon={<img src={iconPolygon} alt="custom polygon icon" />}
                        style={{
                            width: '150px',
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: isDrawActive ? 'red' : '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={handleDrawPolygon}
                    >
                        Polygon
                    </Button>
                </div>
            </div>

            {/* <div style={{ marginBottom: '20px'}}> */}
            {paramsCatalogGeometry !== null ? (
                <ResultCatalog />
            ) : null}
            {/* </div> */}
        </React.Fragment>
    );
};
