// src/components/TableComponent.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Input, Space, Modal, Select } from 'antd';
import { getAllGeoTransactions } from '../../../../firebase/firebase.action';
import useReduxUser from '../../../../hoc/hooks/useUser';
import type { InputRef } from 'antd';
import Text from '../../../../style/styledComponent/Text';
import { DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';

const { Search } = Input;
const { Option } = Select;

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for dd/mm/yy format
};
function isCurrency(price: any) {
  if (price) {
    return `Rp ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  }
  return 'Rp 0'; // Return a default value if price is null or undefined
}

function isNumeric(num: any) {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}

const getStatusButtonColor = (status: string) => {
  switch (status) {
    case 'PENDING':
      return '#f5d664';
    case 'EXPIRED':
      return '#f57f64';
    case 'PAID':
      return '#64f5b6';
    default:
      return '#f5d664';
  }
};

const getStatusProsessButtonColor = (status: string) => {
  switch (status) {
    case 'success':
      return '#64f5b6';
    case 'waiting':
      return '#f5d664';
    case 'warning':
      return '#f5d664';
    case 'cancel':
      return '#f57f64';
    case 'process':
      return '#64c5f5';
    case 'processing':
      return '#64f5e9';
    case 'failed':
      return '#f57f64';
    case 'queue':
      return '##f5a864';
    default:
      return '#f5d664';
  }
};

export const TableComponent: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedFileType, setSelectedFileType] = useState<string>('');
  const { user } = useReduxUser();
  const { setInvoiceForData, invoiceForData } = useReduxCatalog();
  const searchInputRef = useRef<InputRef>(null); // Create a ref for the search input

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const geoTransactions = await getAllGeoTransactions(user?.email); // Use user.uid instead of user.email
          const formattedData = geoTransactions.map((doc: any) => ({
            key: doc.id,
            ...doc,
          }));
          setData(formattedData);
          setFilteredData(formattedData); // Initialize filteredData with all data
        } catch (error) {
          console.error('Failed to fetch geo transactions:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [user]);

  const handleSearch = (value: string) => {
    const filtered = data.filter(item =>
      Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setFilteredData(data);
    if (searchInputRef.current && searchInputRef.current.input) {
      searchInputRef.current.input.value = ''; // Clear the search input value
    }
  };

  const handleDownloadClick = (record: any) => {
    setInvoiceForData(record?.order_id)

  };
  // console.log(selectedRecord?.data?);


  const handleModalOk = () => {
    if (selectedRecord && selectedFileType) {
      // const fileUrl = selectedRecord.data[selectedFileType].signed_url;
      const fileUrl = selectedRecord?.data?.[0]?.other_format[selectedFileType].urls;
      window.open(fileUrl, '_blank');
      setIsModalVisible(false);
      setSelectedFileType('');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedFileType('');
  };

  const handleFileTypeChange = (value: string) => {
    setSelectedFileType(value);
  };

  // console.log(invoiceForData);

  useEffect(() => {
    if (invoiceForData) {
      setSelectedRecord(invoiceForData);
      setIsModalVisible(true);
    }

  }, [invoiceForData]);


  const columns = [

    {
      title: 'Order Number',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      render: (_: any, record: any) => {
        const nameFromDataItems = record.dataItems?.[0]?.dataIds?.[0]?.product || '-';
        return nameFromDataItems;
      },
    },
    {
      title: 'Total Unit',
      dataIndex: 'totalUnit',
      key: 'totalUnit',
      render: (_: any, record: any) => {
        const nameFromDataItems = isNumeric(record.dataItems?.[0]?.dataIds?.[0]?.total_grid) || 0;
        return nameFromDataItems;
      },
      // render: (number: any) => isNumeric(number) || '-', // Apply currency formatting
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (price: any) => isCurrency(price) || '-', // Apply currency formatting
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => formatDate(date) || '-', // Format the date
    },

    {
      title: 'Status Payment',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <Button
          style={{
            width: '100px',
            height: '25px',
            display: 'flex',
            borderColor: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getStatusButtonColor(record.status || record.status_data)
          }}
        >
          <Text style={{ fontWeight: 500, textAlign: 'center', color: 'white' }}> {record.status || 'EXPIRED'} </Text>
        </Button>
      ),
    },
    {
      title: 'Status Data',
      dataIndex: 'status_data',
      key: 'status_data',
      render: (_: any, record: any) => (
        <Button
          style={{
            width: '100px',
            height: '25px',
            display: 'flex',
            borderColor: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getStatusProsessButtonColor(record.status_data)
          }}>
          <Text style={{ fontWeight: 500, textAlign: 'center', color: 'white' }}> {record.status_data || 'EXPIRED'}</Text>
        </Button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <div>
          <Button
            style={{ backgroundColor: 'transparent', marginRight: '5px' }}
            // disabled={record.status_data !== 'success'}
            disabled
            onClick={() => handleDownloadClick(record)}
            icon={<DownloadOutlined style={{
              color: 'white'
            }} />} // Add the DownloadOutlined icon
          >
          </Button><Button
            style={{ backgroundColor: 'transparent' }}
            // disabled={record.status_data !== 'success'}
            disabled
            icon={<FileAddOutlined style={{
              color: 'white'
            }} />} // Add the DownloadOutlined icon
          >
          </Button>
        </div>
      ),
    },
    // Add more columns as needed based on your data structure
  ];

  return (
    <div >
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          style={{ width: 200 }}
          ref={searchInputRef} // Attach the ref to the Search component
        />
        <Button onClick={handleReset}>Reset</Button>
      </Space>
      <Table
        rowClassName={() => 'custom-row'}
        columns={columns} dataSource={filteredData} loading={loading}

      />
      <Modal
        title="Select File Type"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          placeholder="Select a file type"
          style={{ width: '100%' }}
          onChange={handleFileTypeChange}
        >
          {invoiceForData &&
            Object.keys(invoiceForData?.data?.[0]?.other_format).map(fileType => (
              <Option key={fileType} value={fileType}>
                {fileType}
              </Option>
            ))}


        </Select>
      </Modal>
    </div>
  );
}
