import maplibregl from 'maplibre-gl';
let currentMarker: maplibregl.Marker | null = null;

// Function to remove the current marker
export const removeCurrentMarker = () => {
    if (currentMarker) {
        currentMarker.remove();
        currentMarker = null;
    }
};

export const enablePointCreation = (
    map: maplibregl.Map,
    isPointActive: boolean,
    callback: (lngLat: maplibregl.LngLat) => void
    ) => {
    
    const onMapClick = async (e: maplibregl.MapMouseEvent) => {
        if (isPointActive) {  // Ensure click is handled only when isPointActive is true
            removeCurrentMarker();
            // Place a new marker
            currentMarker = new maplibregl.Marker({ draggable: true })
                .setLngLat(e.lngLat)
                .addTo(map);
                callback(e.lngLat);  // 
            // await addIsochroneLayer(map, e.lngLat);
            // Update isochrone when marker is dragged
            currentMarker.on('dragend', async () => {
                if (currentMarker) {
                    const newLngLat = currentMarker.getLngLat();
                    callback(newLngLat);
                    // await addIsochroneLayer(map, newLngLat);
                }
            });
        }
        map.off('click', onMapClick);  // Remove the listener if isPointActive is false
    };

    if (isPointActive) {
        map.on('click', onMapClick);  // Attach the listener only when isPointActive is true
    } else {
        map.off('click', onMapClick);  // Remove the listener if isPointActive is false
        removeCurrentMarker(); // Ensure marker is removed when point mode is off
        if (map.getSource('isochrone-source')) {
            map.removeLayer('isochrone-layer');
            map.removeSource('isochrone-source');
        }
    }
};

