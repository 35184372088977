import React from 'react';
import { Space,Button, Tooltip, message} from 'antd';
import location from '../../../../asesst/Icons/interactivemap/icon/layer3-white.svg'
import maplibregl from 'maplibre-gl';
interface LocationToolsProps {
    map: maplibregl.Map | null; // Ensure that map is passed as a prop
  }
export const LocationToolsMobile: React.FC<LocationToolsProps> = ({ map }) => {
    const handleGeolocation = () => {
        if (!map) {
          message.error('Map is not loaded.');
          return;
        }
    
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
    
              // Fly the map to the user's location
              map.flyTo({
                center: [longitude, latitude],
                zoom: 12, // Adjust the zoom level as needed
                speed: 1.5, // Fly transition speed
              });
    
              // Create a marker for the user's location
              new maplibregl.Marker({ color: 'red' }) // Set marker color to red
                .setLngLat([longitude, latitude]) // Set the marker at the user's location
                .addTo(map); // Add the marker to the map
    
            },
            (error) => {
              message.error('Unable to retrieve your location.');
              console.error(error);
            },
            {
              enableHighAccuracy: true, // Use high accuracy for better results
            }
          );
        } else {
          message.error('Geolocation is not supported by your browser.');
        }
      };
    
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: '15px',
                    bottom: 150,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip 
                    title={<p style={{color:'black'}}>location</p>}
					placement="right"
					color='white'
                    >
                    <Button
                       id='end-point'
                        style={{
                            backgroundColor: '#142943', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "50px",
                            width: "50px",
                            borderRadius:'12px',
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        onClick={handleGeolocation}
                    >
                    <img 
                        src={location}
                        alt="Button Icon" 
                    />
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}