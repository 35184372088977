import React, { useEffect, useState } from 'react';
import { Space } from 'antd';

import { MobileCardListCatalog } from '../../../shared/map/card/mobile/card.mobile';

export const MobileCardCatalog: React.FC<any> = ({ selectedCategory, location }) => {    
    const [topPosition, setTopPosition] = useState(250); // Default top position
    const [height, setHeight] = useState(window.innerHeight); // Default to current screen height
  
    const cardsData = Object.values(selectedCategory).flat().map((item: any) => (    
    {
        title: item?.product,
        productId: item.product_id,
        description: item?.description,
        price: item.price?.minPrice ,
        carPrice: item.price?.minPrice != null 
        ? `Rp ${item?.price?.minPrice.toLocaleString('id-ID')}` 
        : 'Free',
        size: item?.size < 50 ? '5x5' : '50x50',
        totalpixel: item?.price.total_pixel,
        status: item?.status,
        areaSize:location?.area ,
        output: item?.output_type,
        year: item?.versions?.info,
        address: location?.address,
        id: item?.id,
        readiness: item?.readiness,
        polygonId: item?.polygon_id,
        subData: item?.subdata,
        version: item?.versions,
        typeDataId: item.type_id,
        radius: item.radius,

        centeroid: {
            lattitude: location?.centroid
            [0],
            longitude: location?.centroid
            [1]
        }
    }));
    // console.log(cardsData);
    useEffect(() => {
        const handleResize = () => {
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;
    
          // Update height
          setHeight(screenHeight);
    
          // Jika layar ponsel (<= 480px), set top position berbeda
          if (screenWidth <= 480) {
            setTopPosition(screenHeight * 0.10); // Misal, 15% dari tinggi layar
          } else {
            setTopPosition(screenHeight * 0.25); // Misal, 25% dari tinggi layar untuk layar lebih besar
          }
        };
    
        // Run on mount and when resizing
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    
    return (
        <React.Fragment>
            <Space
                direction="horizontal"
                style={{
                    position: 'absolute',
                    top: topPosition,
                    height: height * 0.5,
                    transition: '0.25s ease-in-out',
                    overflowY: 'auto',
                }}
            >
                 <MobileCardListCatalog dataCard={cardsData} />
            </Space>
        </React.Fragment>
    );
};
