import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
    paymentCreateInvoice,
    paymentTopUp,
    setPaymentv2Action,
    setCreatePaymentWalletAction
 } from '../action/payment.action';

const useReduxPayment = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const { 
        data, 
        isloading, 
        error,
        dataTopUp,
        createPayment,
        createPaymentWallet
     } = useSelector((state: RootState) => state.payment);

    const paymentDispatch = async (data: any) => {
        return await dispatch(paymentCreateInvoice(data)); // Ensure the action is awaited and returned
    };
    const topUpPayment = async (token: string, data: any) => {
        return await dispatch(paymentTopUp(token, data)); // Ensure the action is awaited and 
    };
    const setPaymentv2 = async (data: any) => {
        return await dispatch(setPaymentv2Action( data)); // Ensure the action is awaited and 
    };
    const setCreatePaymentWallet = async (data: any) => {
        return await dispatch(setCreatePaymentWalletAction(data)); // Ensure the action is awaited and 
    };

    return {
        paymentDispatch,
        topUpPayment,
        setPaymentv2,
        setCreatePaymentWallet,
        createPaymentWallet,
        createPayment,
        dataTopUp,
        data,
        isloading,
        error
    };
};

export default useReduxPayment;
