export const formatNumberWithDots = (number: number | undefined): string => {
    if (number === undefined) {
        return '0'; // or another default string if you want to handle undefined differently
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatNumberSqm = (num: number) => {
    return parseFloat(num.toFixed(2));
};

export const formatNumberReachSqm = (value: number): string => {
    if (value === 0) return '0';  // Jika nilai 0, langsung kembalikan '0'
    
    const formattedValue = (value / 100000).toFixed(1);
    return formattedValue;
  };
  
  export const convertToKm2 = (value: number) => {
    return value / 1_000_000;
}
