import React, { useState } from 'react';
import { Button, Space } from 'antd';
interface TabsSeriesProps {
    setDataSeries: (value: string) => void;
  }
export const TabsSeries: React.FC<TabsSeriesProps> = ({ setDataSeries }) => {
    const [activeTab, setActiveTab] = useState('All Time');
    const handleTabClick = (params: string) => {
        let transformedParams = params;
        if (params === 'Early Morning') {
            transformedParams = 'Earlymorning';
        }
        if (params === 'All Time') {
            transformedParams = 'Total';
        }
        setActiveTab(params);
        setDataSeries(transformedParams);
      };
    
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    backgroundColor: '#1E334D',
                    right: '30%',
                    bottom: '5%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                    borderRadius: '12px',  // Adding rounded corners to the container
                    padding: '5px',        // Adding some padding around the container
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        gap: '10px', // Adding gap between the tabs for even spacing
                    }}
                >
                    {['All Time','Early Morning', 'Morning', 'Afternoon', 'Evening', 'Night'].map((tab) => (
                        <Button
                            type="text"
                            key={tab}
                            id={tab}
                            onClick={() => handleTabClick(tab)}
                            style={{
                                borderRadius: activeTab === tab ? '12px' : '0%', // Rounded corners for the active tab
                                backgroundColor: activeTab === tab ? 'white' : '#1E334D',
                                color: activeTab === tab ? '#1E334D' : 'white',
                                fontWeight: activeTab === tab ? 'bold' : 'normal',
                                padding: '0 15px', // Padding to space out the text inside the tabs
                                height: '40px',     // Height to ensure the buttons are even
                                border: activeTab === tab ? 'none' : 'none', // Remove border on active/inactive
                            }}
                        >
                            {tab}
                        </Button>
                    ))}
                </div>
            </Space>
        </React.Fragment>
    );
};
