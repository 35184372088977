import React, { useState } from 'react';
import { Space,Button, Tooltip} from 'antd';
import iconPolygon from '../../../../asesst/Icons/interactivemap/icon/polygon.svg';
import { handleDrawClick, initializeMapboxDraw, removeDrawControl } from '../../../../component/newmap/helper/map/draw.polygon';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import { handleReverseGeocode } from '../../../../component/newmap/helper/map/reversecode';
interface DrawerProps {
    map: maplibregl.Map | null; // Pass the map instance as a prop
}
export const DrawersMobile: React.FC<DrawerProps> = ({ map }) => {
    const { setShowDrawerBottom,setShowCardCatalog,setShowCardMyData,setCatalogGeometry, setCatalogLocation } = useReduxCatalog();
    const [isDrawActive, setIsDrawActive] = useState<boolean>(false); // State to track draw control
    const [draw] = useState<MapboxDraw>(initializeMapboxDraw);

    const handleDrawPolygon = () => {
        if (isDrawActive) {
            // If draw control is active, remove it on button click
            removeDrawControl(map, draw);
            map?.off('draw.create', handleDrawCreate); // Remove event listener
            setShowCardCatalog(false)
            setShowCardMyData(false);
        } else {
            // If draw control is not active, add it
            handleDrawClick(map, draw);
            map?.on('draw.create', handleDrawCreate); // Add event listener
        }
        setIsDrawActive(!isDrawActive); // Toggle draw control state
    };
    const handleDrawCreate = async (event: any) => {
        const polygon = event.features[0].geometry;
        setCatalogGeometry(polygon);
        try {
            const locationCode = await handleReverseGeocode(polygon);
            setShowDrawerBottom(true)
            setCatalogLocation(locationCode);
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };
  return (
    <React.Fragment>
    <Space
      direction='vertical'
      style={{
        position: 'absolute',
        right: '15px',
        top: 140,
        zIndex: 100,
        transition: '0.25s ease-in-out',
      }}
    >
      <Tooltip 
        title={<p style={{color:'black'}}>Draw Polygon</p>}
        placement="top"
        color='white'
      >
        <Button
          id='end-point'
          style={{
            backgroundColor: '#142943',
            color: 'black',
            height: "50px",
            width: "50px",
            borderRadius:'12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        onClick={handleDrawPolygon}
        >
          <img 
            src={iconPolygon}
            alt="Layer Icon" 
          />
        </Button>
      </Tooltip>
    </Space>
      </React.Fragment>
  );
};
