import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Input, Button, Row, Col, message } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../../style/styledComponent/Container";
import { fadein } from '../../../../../style/keyframes';
import servicesIcon from '../../../../../asesst/new/icon/services.svg';
import { saveFormDataTalkToUs } from '../../../../../firebase/firebase.action';

const { Title, Text } = Typography;


const TalkToUsSectionMobile = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Menggunakan useCallback untuk validateForm
    const validateForm = useCallback(() => {
        const { name, email, phone, company, message } = formData;
        const isValid = name !== '' && email !== '' && phone !== '' && company !== '' && message !== '';
        setIsFormValid(isValid);
    }, [formData]);

    useEffect(() => {
        validateForm();
    }, [formData, validateForm]); // Menambahkan validateForm ke array dependensi

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '');
        setFormData({ ...formData, phone: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!isFormValid) return;

            // console.log(formData);
            await saveFormDataTalkToUs(formData);
            message.success('Success submit data, Our team will contact you');
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                message: '',
            });
        } catch (error) {
            console.error('Failed to submit data', error);
            message.error('Failed to submit data. Please try again.');
        }
    };

    const containerStyles: CSSProperties = {
        display: 'flex',
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        alignItems: 'flex-start',
        position: 'relative',
        justifyContent: 'space-between',
        animation: `1s ease-in 1s 1 both ${fadein}`,
        backgroundColor: '#093870',
        width: '100%',
        padding: windowWidth <= 768 ? '20px' : '100px',
        color: '#fff',
    };

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            // display: 'flex',
            paddingTop: '50px',
            alignItems: 'left',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            textAlign: 'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        title: {
            fontWeight: '800',
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#fff',
            marginTop: '10px',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        description: {
            fontSize: '16px',
            color: '#ffff',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        integrationText: {
            color: '#316DBA',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        formContainer: {
            backgroundColor: 'transparent',
            padding: '0px',
            borderRadius: '8px',
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        formItem: {
            marginBottom: '20px',
        },
        input: {
            borderRadius: '8px',
            padding: '10px',
            width: '100%',
            color:'white',
            backgroundColor:'#202B4E',
            borderColor:'black',
            border: '1px solid black',
        },
        textArea: {
            borderRadius: '8px',
            color:'white',
            backgroundColor:'#202B4E',
            borderColor:'black',
            border: '1px solid black',
            padding: '10px',
            width: '100%',
        },
        button: {
            width: '100%',
            height: '54px',
            backgroundColor: '#316DBA',
            color: '#fff',
            border: 'none',
            borderRadius: '8px',
            padding: '10px 0',
            fontSize: '16px',
            cursor: 'pointer',
        },
        buttonDisabled: {
            backgroundColor: '#A9A9A9',
            cursor: 'not-allowed',
        }
    };


    return (
        <Container data-testid="app" style={containerStyles}>
            <Row justify="start" align="top" gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <div style={styles.integrationHeader}>
                        <img alt="example" src={servicesIcon} style={styles.icon} />

                    </div>
                    <Text style={styles.integrationText}>Talk to us</Text>
                    <Title style={styles.title}>
                        Need an in-depth discussion regarding your needs?
                    </Title>
                    <Text style={styles.description}>
                        We will help your business with appropriate geospatial-based solutions to make it more effective and efficient.
                    </Text>
                </Col>
                <Col xs={24} md={12}>
                    <div style={styles.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formItem}>
                                <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Name</Text>
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="white-placeholder"
                                    placeholder="Ex : Jhon Doe"
                                    style={styles.input}
                                    required
                                />
                            </div>
                            <div style={styles.formItem}>
                                <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Email</Text>
                                <Input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Ex : mail@yourmail.com"
                                    className="white-placeholder"
                                    style={styles.input}
                                    required
                                />
                            </div>
                            <div style={styles.formItem}>
                                <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Phone Number</Text>
                                <Input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    placeholder="Ex : 628121212"
                                    className="white-placeholder"
                                    style={styles.input}
                                    maxLength={15}
                                    required
                                />
                            </div>
                            <div style={styles.formItem}>
                                <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Company</Text>
                                <Input
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    className="white-placeholder"
                                    placeholder="Ex : Geo Inovasi Nusantara"
                                    style={styles.input}
                                    required
                                />
                            </div>
                            <div style={styles.formItem}>
                                <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Message</Text>
                                <Input.TextArea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows={8}
                                    placeholder="Your message"
                                    maxLength={300}
                                    className="white-placeholder"
                                    style={styles.textArea}
                                    required
                                />
                            </div>
                            <Button htmlType="submit" style={isFormValid ? styles.button : { ...styles.button, ...styles.buttonDisabled }} disabled={!isFormValid}>
                                <span style={{ color: 'white', fontSize: '17px', fontWeight: '700', fontFamily: '"Jakarta Sans", sans-serif', }}>Say hi to us!</span>
                            </Button>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default TalkToUsSectionMobile;
