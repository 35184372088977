import React, { useState } from 'react';
import { Button, Space } from 'antd';

interface TabsSeriesProps {
    setDataSeries: (value: string) => void;
}

export const TabsSeriesMobile: React.FC<TabsSeriesProps> = ({ setDataSeries }) => {
    const [activeTab, setActiveTab] = useState('All Time');
    
    const handleTabClick = (params: string) => {
        let transformedParams = params;
        if (params === 'Early Morning') {
            transformedParams = 'Earlymorning';
        }
        if (params === 'All Time') {
            transformedParams = 'Total';
        }
        setActiveTab(params);
        setDataSeries(transformedParams);
    };
    
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    backgroundColor: '#1E334D',
                    right: '3%',
                    bottom: '35%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                    borderRadius: '8px',  // Rounded corners
                    padding: '3px',        // Reduced padding around container
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                    }}
                >
                    {['All Time','Early Morning', 'Morning', 'Afternoon', 'Evening', 'Night'].map((tab) => (
                        <Button
                            type="text"
                            key={tab}
                            id={tab}
                            onClick={() => handleTabClick(tab)}
                            style={{
                                borderRadius: activeTab === tab ? '8px' : '0%',  // Reduced border radius
                                backgroundColor: activeTab === tab ? 'white' : '#1E334D',
                                color: activeTab === tab ? '#1E334D' : 'white',
                                fontWeight: activeTab === tab ? 'bold' : 'normal',
                                padding: '0 10px',  // Reduced padding inside the tabs
                                height: '30px',     // Reduced height of the buttons
                                fontSize: '10px',   // Reduced font size
                                border: 'none', // No borders
                            }}
                        >
                            {tab}
                        </Button>
                    ))}
                </div>
            </Space>
        </React.Fragment>
    );
};
