import * as turf from '@turf/turf';
import maplibregl from 'maplibre-gl';
import icAreaSize from '../../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import { formatNumberWithDots } from '../formatNumber';
export interface GeoJSONPolygon {
    type: 'Polygon';
    coordinates: number[][][];
}

export interface GeoJSONFeature {
    type: 'Feature';
    geometry: GeoJSONPolygon;
    properties: Record<string, any>;
}
let currentPopup: maplibregl.Popup | null = null;

const removePopup = () => {
    if (currentPopup) {
        currentPopup.remove();
        currentPopup = null;
    }
};

export const clearGeocodeLayer = ( map: maplibregl.Map | null,) => {
    if (map && map.getSource('geocode-result')) {
        map.removeLayer('geocode-layer');
        map.removeSource('geocode-result');
    }
};
export const renderGeocodeResultToMap = (
    map: maplibregl.Map | null,
    geojson: GeoJSONFeature,
    sourceId: string = 'geocode-result',
    layerId: string = 'geocode-layer'
): void => {
    if (!map) return;
    // Remove previous layer and source if they exist
    if (map?.getSource(sourceId)) {
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
        }
        map.removeSource(sourceId);
    }

    // Add new source
    map?.addSource(sourceId, {
        type: 'geojson',
        data: geojson,
    });

    map?.addLayer({
        id: layerId, // Unique ID for the fill layer
        type: 'fill',
        source: sourceId,
        layout: {},
        paint: {
            'fill-color': '#4A7FC0', // Fill color for the polygon
            'fill-opacity': 0.2,     // Adjust the opacity as needed
        },
    });

    // Add new layer
    map?.addLayer({
        id: layerId,
        type: 'line',
        source: sourceId,
        layout: {},
        paint: {
            'line-color': '#4A7FC0',
            'line-width': 2,
        },
    });

    const bbox = turf.bbox(geojson);
    const northwest = [bbox[0], bbox[3]]; // Coordinates of the northwestern corner of the bounding box
    const area = turf.area(geojson); // Returns area in m²
    const areaInKm2 = area / 1_000_000; // Convert to km²
    const formattedAreaInKm2 = areaInKm2.toFixed(0); // Format to 2 decimal places
    // Remove the previous popup if it exists
    if (currentPopup) {
        currentPopup.remove();
    }

    // Create and show the new popup at the northwestern corner
    removePopup(); // Remove existing popup if any
    currentPopup = new maplibregl.Popup({ className: 'radius-popup', closeOnClick: false })
        .setLngLat(northwest as [number, number])
        .setHTML(`
        <div style="display: flex; align-items: center;">
            <img src=${icAreaSize} alt="icon" style="width: 20px; height: 20px; margin-right: 5px;" />
            <h3 style="color: white; margin: 0;">Size Area</h3>
        </div>
        <h3 style="color: white; margin: 5px 0; margin-left: 10px">${formatNumberWithDots(parseFloat(formattedAreaInKm2))} km²</h3>
    `)
        .addTo(map);

    // Convert the bounding box to a LngLatBounds object
    const bounds = new maplibregl.LngLatBounds(
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]]
    );

    map?.fitBounds(bounds, {
        padding: 20,
        maxZoom: 15,
        duration: 1000
    })
    map.on('sourcedata', (e) => {
        if (!map.getSource(sourceId)) {
            removePopup();
        }
    });
};
