import React from 'react';
import { Card, Button } from 'antd';
// import { FaRegClock } from "react-icons/fa";
import Text from '../../../style/styledComponent/Text';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icYearClock from '../../../asesst/Icons/interactivemap/icon/year-clock.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
// import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import { ModalDefault } from '../modal/modal-checkout';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
// import { ModalConfirmPayment } from '../modal/modal-confirm-payment';

interface InsightCardProps {
  id: string;
  polygonId: string;
  readiness: string;
  subData: any;
  version: any;
  title: string;
  productId: string;
  carPrice: string;
  description: string;
  price: string;
  size: any,
  totalpixel: any,
  status: string,
  output: any,
  year: number
  areaSize: any
  address: any,
  radius:any,
  centeroid: {
    lattitude: number,
    longitude: number
  }
}

interface CardListProps {
  dataCard: InsightCardProps[]; // Array of cards to be displayed
}

export const CardListCatalog: React.FC<CardListProps> = ({ dataCard }) => {
  const { 
    setModalOpenCheckout,
    setSelectedItem,
    isModalCheckout, 
    selectedItem,
    } = useReduxCatalog();

  // Function to handle opening the modal
  const showModal = (item: InsightCardProps) => {
    setSelectedItem(item);
    setModalOpenCheckout(true);
  };

  return (
    <div style={{ height: 'calc(100vh - 50px)', overflowY: 'auto', paddingRight: '8px' }}>
      {dataCard.map((item, index) => (    
        <div
          key={index}
          className="custom-card"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.3s ease', // Smooth transition
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateX(10px)'; // Move to the right by 10px
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateX(0)'; // Reset position
          }}
        >
          <Card
            style={{
              width: 320,
              backgroundColor: '#141617',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', marginBottom: 16 }}>
              <div>
                <Text style={{ color: 'white', margin: 0, fontSize: '16px', fontWeight:'bold',paddingRight:'25px', width:'240px' }}>{item.title}</Text>
                <Text style={{ color: 'lightgray', margin: 0, fontSize: '14px', width:'220px' }}>{item.description}</Text>
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '5px' }}>
                  <img
                    alt="example"
                    src={icAreaSize}
                  />
                  <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{formatNumberWithDots(item.areaSize)} km²
                  </Text>
                  <img
                    alt="example"
                    src={icGridTotal}
                  />
                  <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{formatNumberWithDots(item.totalpixel)}</Text>
                  <img
                    alt="example"
                    src={icYearClock}
                  />
                  <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{item.year}</Text>
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'left', gap: '10px', marginBottom: '5px', marginTop: '3px' }}>
                  <FaRegClock style={{ color: 'white' }} /> <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{item.year}</Text>
                </div> */}
                {/* <Text style={{ color: 'white', margin: 0, fontSize: '14px' }}>Start From</Text> */}
                <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0 }}>{item.carPrice}</h3>
              </div>
              <div
                style={{
                  background: 'white',
                  padding: '5px',
                  width: '60px',
                  height: '20px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>{item.size}</Text>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly',gap: 4 }}>
              {/* <Button
               onClick={() => showModal(item)} // Show modal when clicked
                id='end-point'
                style={{
                  backgroundColor: 'transparent', // Ubah warna tombol menjadi putih
                  color: '#316DBA', // Ubah warna teks menjadi hitam
                  display: 'flex',
                  height:'40px',
                  width:'42px',
                  borderColor: 'white',
                  justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                  alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                }}
              >
                <img
                  src={icChart}
                  alt="Button Icon"
                  width={20}
                  height={20}
                />
              </Button> */}
              <Button 
              style={{ 
                borderRadius: '4px', 
                color: 'white', 
                backgroundColor: 'transparent', 
                borderColor: 'white' ,
                height:'30px',
                width:'150px'
              }}
              onClick={() => showModal(item)} // Show modal when clicked
              >View Detail</Button>
              <Button
                style={{
                   borderRadius: '4px', 
                   backgroundColor: '#4A7FC0', 
                   borderColor: 'transparent', 
                   color: 'white', 
                   height:'30px',
                   width:'150px'
                  }}
                onClick={() => showModal(item)} // Show modal when clicked
              >
                {item.price === 'Free' ? 'Get Data' : 'Buy'}
              </Button>
            </div>
          </Card>
        </div>
      ))}
      <ModalDefault 
        isModal={isModalCheckout}
        data={selectedItem}
      />
    </div>
  );
};
