import React, { useEffect, useState } from 'react';
import { Layout, Card, Button, List, Typography, message, Popconfirm, Spin,Empty } from 'antd';
import moment from 'moment';
import { IoTrashOutline } from "react-icons/io5";
import useReduxOneApi from '../../hoc/hooks/useOneApi';
import { auth } from '../../firebase/firebaseConfig';
import { BsCopy } from "react-icons/bs";
import useReduxUser from '../../hoc/hooks/useUser';
const { Content } = Layout;
const { Title, Text } = Typography;

interface ApiKey {
  key: string;
  generatedAt: string;
}

interface PrevApiKey {
  active: boolean;
  [key: string]: any; // Allow for additional properties
}

const convertFirebaseTimestamp = (timestamp: { _seconds: number, _nanoseconds: number }) => {
  const timestampInMilliseconds = (timestamp._seconds * 1000) + (timestamp._nanoseconds / 1000000);
  return moment(timestampInMilliseconds).format('YYYY-MM-DD HH:mm:ss');
};

export const GenerateApiKeysPage: React.FC = () => {
  const { generateApiKeys, listApiKeys, getlistApiKey } = useReduxOneApi();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { dataUser } = useReduxUser();


  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('KEY copied to clipboard');
    });
  };

  const prevKeys: PrevApiKey[] = Object.entries(dataUser?.apiKeysData ?? {}).flatMap(([key, value]) => {
    const apiKeyValue = value as PrevApiKey; // Type assertion

    if (typeof apiKeyValue === "object" && apiKeyValue !== null && !apiKeyValue.active) {
      return [{
        key, // Set the key explicitly
        ...apiKeyValue, // Spread the rest of the properties
      }];
    }

    if (!(value as PrevApiKey)?.active) {
      return [{
        key, // Set the key explicitly
        active: false, // Ensure 'active' exists
      }];
    }

    return []; // Return an empty array if active is true
  });


  const generateApiKey = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const token = await user.getIdToken();
      await generateApiKeys(token);
      message.success('API key generated successfully');
      // Fetch the updated list of API keys after generating a new one
      await fetchApiKeys();
    } catch (error) {
      // console.error('Error generating API key:', error);
      message.error('Failed to generate API key');
    }
  };
  const fetchApiKeys = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const token = await user.getIdToken();
      await getlistApiKey(token);
    } catch (error) {
      // console.error('Error fetching API keys:', error);
      // message.error('Failed to fetch API keys');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('listApiKeys updated:', listApiKeys); // Debugging line
    if (listApiKeys) {
      const convertedData = listApiKeys.map((item: any) => ({
        ...item,
        generatedAt: convertFirebaseTimestamp(item.createdAt) // Ensure `createdAt` is available
      }));
      // console.log('Converted data:', convertedData); // Debugging line
      setApiKeys(convertedData || []);
    }
  }, [listApiKeys]);

  const deleteApiKey = (key: string) => {
    setApiKeys(apiKeys.filter(k => k.key !== key));
    message.success('API key deleted successfully');
  };
  const customEmpty = {
    emptyText: (
      <div style={{ textAlign: 'center', color: 'black' }}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} // Replace with your custom image if needed
          description={
            <>
              <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                No data available
              </span>
              <br />
              <span style={{ color: 'white', fontSize: '12px' }}>
                Please check back later.
              </span>
            </>
          }
        />
      </div>
    ),
  };
  return (
    <Layout style={{ padding: '44px', minHeight: '100vh', background: "#272B34", }}>
      <Content>
        <Card
          style={{ marginBottom: '24px', backgroundColor: '#363A45' }}>
          <Popconfirm
            title="Generate new key?"
            onConfirm={() => generateApiKey()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" style={{ marginBottom: '16px' }}>
              Generate API Key
            </Button>
          </Popconfirm>
          {loading ? (
            <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: '20px' }} />
          ) : (
            <>
              <List
                header={<Title style={{ color: 'white' }} level={4}>API Keys</Title>}
                bordered
                locale={customEmpty} 
                dataSource={apiKeys}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Button type="link" onClick={() => copyToClipboard(item.key)}>
                        <BsCopy size={20} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                      </Button>,
                      <Popconfirm
                        title="Are you sure you want to delete this API key?"
                        onConfirm={() => deleteApiKey(item.key)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" danger>
                          <IoTrashOutline size={20} />
                        </Button>
                      </Popconfirm>,
                    ]}
                  >
                    <div>
                      <Text style={{ color: 'white' }}>{item.key}</Text>
                      <br />
                      <Text style={{ color: 'white' }} type="secondary">Generated at: {item.generatedAt}</Text>
                    </div>
                  </List.Item>
                )} />
              <br></br>
              <List
                header={<Title style={{ color: 'white' }} level={4}>Previous API Keys</Title>}
                bordered
                locale={customEmpty} 
                dataSource={prevKeys}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Button type="link" onClick={() => copyToClipboard(item.key)}>
                        <BsCopy size={20} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                      </Button>,
                      <Popconfirm
                        title="Are you sure you want to delete this API key?"
                        onConfirm={() => deleteApiKey(item.key)}
                        okText="Yes"
                        cancelText="No"
                      >
                      </Popconfirm>,
                    ]}
                  >
                    <div>
                      <Text style={{ color: 'white' }}>{item.key}</Text>
                      <br />
                      <Text style={{ color: 'white' }}> status - </Text>
                      <Button
                        style={{
                          borderColor: 'transparent',
                          color: 'white',
                          backgroundColor: item.active ? '#81C784' : '#E57373'
                        }}
                      >
                        {item.active ? 'live' : 'expired'}
                      </Button>
                    </div>
                  </List.Item>
                )} />
            </>
          )}
        </Card>
      </Content>
    </Layout>
  );
};
