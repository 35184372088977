import MapboxDraw from '@mapbox/mapbox-gl-draw';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf';
import icAreaSize from '../../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import { formatNumberWithDots } from '../formatNumber';
export interface GeoJSONPolygon {
    type: 'Polygon';
    coordinates: number[][][];
}
let currentPopup: maplibregl.Popup | null = null;
export interface GeoJSONFeature {
    type: 'Feature';
    geometry: GeoJSONPolygon;
    properties: Record<string, any>;
}

// Initialize the Mapbox Draw instance
export const initializeMapboxDraw = (): MapboxDraw => {
    return new MapboxDraw({
        displayControlsDefault: false,
        styles: [
            {
                id: "gl-draw-polygon-fill",
                type: "fill",
                paint: {
                    "fill-color": "#4A7FC0",
                    "fill-opacity": 0.1
                }
            },
            {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                layout: {
                    "line-cap": "round",
                    "line-join": "round"
                },
                paint: {
                    "line-color": "#4A7FC0",
                    "line-dasharray": [0.2, 0.2],
                    "line-width": 1
                }
            },
            {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle", // This defines the style for active vertices
                paint: {
                    "circle-radius": 3,
                    "circle-color": "#4A7FC0", // Default circle color
                }
            },
            {
                id: "gl-draw-polygon-and-line-vertex-inactive",
                type: "circle", // Defines the style for inactive vertices
                paint: {
                    "circle-radius": 3, // Adjust size of the vertices
                    "circle-color": "#4A7FC0", // Keep it consistent with active vertices
                }
            }
        ]
    });
};
let previousPolygonId: string | null = null;

export const handleDrawClick = (map: maplibregl.Map | null, draw: MapboxDraw): any | null => {
    if (!map) return null;

    // Check if the map already has the draw control
    if (!(map as any).hasControl(draw)) {
        (map as any).addControl(draw);
    }

    // Start the drawing mode
    if (draw) {
        draw.changeMode('draw_polygon'); // Switch to polygon draw mode
    }

    // Listen for the completion of the drawing
    map.on('draw.create', (event: any) => {
        const newPolygon = event.features[0];
        const newPolygonId = newPolygon.id;

        // Delete only the previous polygon (if any), leaving the new one untouched
        if (previousPolygonId && previousPolygonId !== newPolygonId) {
            draw.delete(previousPolygonId);
        }
        previousPolygonId = newPolygonId;

        const areaInSquareMeters = turf.area(newPolygon);
        const areaInKm2 = (areaInSquareMeters / 1_000_000).toFixed(0);
        const centroid = turf.centroid(newPolygon).geometry.coordinates;


        // Remove the previous popup if it exists
        if (currentPopup) {
            currentPopup.remove();
        }

        currentPopup = new maplibregl.Popup({ className: 'radius-popup', closeOnClick: false })
            .setLngLat(centroid as [number, number])
            .setHTML(`
            <div style="display: flex; align-items: center;">
                <img src=${icAreaSize} alt="icon" style="width: 20px; height: 20px; margin-right: 5px;" />
                <h3 style="color: white; margin: 0;">Size Area</h3>
                </div>
            <h3 style="color: white; margin: 5px 0; margin-left: 10px">${formatNumberWithDots(parseFloat(areaInKm2))} km²</h3>
        `).addTo(map);

    });
};


export const removeDrawControl = (map: maplibregl.Map | null, draw: MapboxDraw): any | null => {
    if (!map) return;
    // Check if the MapboxDraw control is on the map
    if ((map as any).hasControl(draw)) {
        // Delete all drawn features (polygons, lines, points)
        draw.deleteAll();
        if (currentPopup) {
            currentPopup.remove();
            currentPopup = null; // Reset currentPopup to null
        }
    }
};

