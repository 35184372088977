import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import LoaderSideBar from '../../../loader/loader.sidebar';
// import { IoChevronForwardOutline } from "react-icons/io5";
// import { CardMyData } from '../../../../component/menu/card.my.data';
import { auth } from '../../../../firebase/firebaseConfig'; // Importing Firebase auth
import useReduxUser from '../../../../hoc/hooks/useUser';
import ModalLogin from '../../modal/modal-login';
import iconHeader from '../../../../asesst/Icons/interactivemap/icon.svg';
import icHiresDemo from '../../../../asesst/Icons/interactivemap/icon/categories2.svg';
import icPeopleTrafic from '../../../../asesst/Icons/interactivemap/icon/categories3.svg';
import icReachability from '../../../../asesst/Icons/interactivemap/icon/categories1.svg';
import { DrawerGroupLayerMobile } from './drawer-group.layer.mobile';
// import MenuDrawer from '../../../component/menu/menu.drawer'; // Adjust the import path as necessary
interface SubmenuFormProps {
    onBack: () => void; // A function prop for going back to the main menu
    map: maplibregl.Map | null; // Adjust this type depending on the map library you are using
}

export const MyDataMobile: React.FC<SubmenuFormProps> = ({ onBack, map }) => {
    const [activeTab, setActiveTab] = useState('Data');
    const [param, setParam] = useState('data');
    // const [showCardMyData, setShowCardMyData] = useState(false);
    const user = auth.currentUser; // Get the current user state
    const { isLoginModalVisible, modalLoginOpen } = useReduxUser();
    const {
        setMyData,
        setDetailMyData,
        myData,
        dataLayerGroup,
        isloading,
        metaData,
        setMetaData,
        setShowCardMyData,
    } = useReduxCatalog();

    const handleTabClick = (params: string) => {
        setActiveTab(params);
        const paramCatalog = params.toLowerCase();
        setParam(paramCatalog);
    };


    const handleCardClick = (item: any) => {
        setDetailMyData(item);
        setShowCardMyData(true);
        setMetaData('');
    };

    const handleLoginModalOpen = () => {
        modalLoginOpen(true);
    };

    useEffect(() => {
        if (user) {
            setMyData(param);

            const intervalId = setInterval(() => {
                setMyData(param);
            }, 10000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    }, [param, user]);

    // Icon mapping based on category
    const iconMapping = {
        hires_demography: icHiresDemo,
        reachability: icReachability,
        people_traffic: icPeopleTrafic,
    };

    const renderTabContent = () => {
        if (isloading && user) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <LoaderSideBar />
                </div>
            );
        }

        if (!user) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'white' }}>
                    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                        <img src={iconHeader} alt="No message" style={{ width: '100px', height: '200px' }} />
                        <p style={{ color: 'white', fontSize: '16px' }}>
                            You need to be logged in to access this section.
                        </p>
                    </div>
                    <button
                        onClick={handleLoginModalOpen}
                        style={{
                            marginTop: '30px',
                            backgroundColor: '#4A7FC0',
                            color: '#fff',
                            width: '100%',
                            fontWeight: 400,
                            padding: '10px 16px',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Go to Login
                    </button>
                </div>
            );
        }

        const isDataNotFound = !myData?.data?.length;
        if (isDataNotFound) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <>
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                                No data available
                            </span>
                            <br />
                            <span style={{ color: 'white', fontSize: '12px' }}>
                                Please check back later or explore other categories.
                            </span>
                        </>
                    }
                />
            );
        }

        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr', // Two columns
                gap: '10px',
            }}>
                {
                    myData?.data.map((item: any, index: any) => {
                        const iconSrc = iconMapping[item?.category as keyof typeof iconMapping] || iconHeader;

                        return (
                            <div
                                onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                                key={index}
                                onClick={() => handleCardClick(item?.category)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    background: '#142943',
                                    padding: '10px',
                                    borderRadius: '12px',
                                    cursor: 'pointer',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                }}
                            >
                                <img
                                    alt="category-icon"
                                    src={iconSrc}
                                    style={{ width: '40px', height: '40px', marginBottom: '10px' }} />

                                <h4 style={{ marginBottom: '5px', fontSize: '12px', textAlign: 'center', color: 'white' }}>{item?.category_alias}</h4>
                                <p>{item?.count_product} Products</p>
                            </div>
                        );
                    })
                }
            </div>
        )

    };

    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#1E334D', color: 'white' }}>
                <div
                    style={{
                        position: 'sticky',
                        top: '-20px',
                        backgroundColor: '#1E334D',
                        zIndex: 1,
                    }}
                >
                    <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                        <span
                            onClick={onBack}
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            ← Result
                        </span>
                    </div>

                    {/* <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
                        {['Data', 'Insight', 'Survey', 'Other'].map((tab) => (
                            <Button
                                type="text"
                                key={tab}
                                id={tab}
                                onClick={() => handleTabClick(tab)}
                                style={{
                                    borderRadius: '0%',
                                    color: activeTab === tab ? '#4A7CF3' : 'white',
                                    fontWeight: activeTab === tab ? 'bold' : 'normal',
                                    padding: '0 10px',
                                    borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
                                }}
                            >
                                {tab}
                            </Button>
                        ))}
                    </div> */}
                    {!metaData && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            {['Data', 'Insight', 'Survey', 'Other'].map((tab) => (
                                <Button
                                    type="text"
                                    key={tab}
                                    onClick={() => handleTabClick(tab)}
                                    style={{
                                        color: activeTab === tab ? '#4A7CF3' : 'white',
                                        fontWeight: activeTab === tab ? 'bold' : 'normal',
                                        borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
                                    }}
                                >
                                    {tab}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                    {/* {renderTabContent()} */}
                    <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                        {/* Render DrawerGroupLayerMobile when metaData is present */}
                        {dataLayerGroup?.length > 0 ? (
                            <DrawerGroupLayerMobile dataLayerGroup={dataLayerGroup} map={map!} />
                        ) : (
                            renderTabContent()
                        )}
                    </div>
                </div>
                <ModalLogin visible={isLoginModalVisible} />
            </div>
        </React.Fragment>
    );
};
