import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import { CardCatalog } from '../../../component/menu/menu.card.catalog';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import mapid from "../../../asesst/Icons/product/mapid.png";
import LoaderSideBar from '../../loader/loader.sidebar';
// import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import { IoChevronForwardOutline } from 'react-icons/io5';
// import icAddress from '../../../asesst/Icons/interactivemap/icon/result.svg';
// import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
// import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';

interface ResultCatalogProps {
  // onBackClick: () => void;
}

export const ResultCatalog: React.FC<ResultCatalogProps> = () => {
  const [activeTab, setActiveTab] = useState('Data');
  // const [showCardCatalog, setShowCardCatalog] = useState(false);
  const {
    setCatalog,
    setShowCardCatalog,
    showCardCatalog,
    paramsCatalogGeometry,
    catalog,
    isloading,
    location } = useReduxCatalog();
  const [param, setParam] = useState('data');
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

  const handleTabClick = (params: string) => {
    setActiveTab(params);
    const paramCatalog = params.toLowerCase();
    setParam(paramCatalog);
  };

  const handleCardClick = (category: any) => {
    setSelectedCategory(category);
    setShowCardCatalog(true);
  };

  useEffect(() => {
    setCatalog(param, paramsCatalogGeometry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, paramsCatalogGeometry, location]);

  const renderTabContent = () => {
    if (activeTab === 'Other') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px', gap: '10px', marginTop:'10px' }}>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            <img
              alt="example"
              src={mapid}
              style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: '8px' }} />
          </span>
          <span style={{ fontSize: 12, lineHeight: '1.5' }}><b>MAPID</b> is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.</span>
        </div>
      );
    }
    if (isloading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoaderSideBar />
        </div>
      );
    }

    const dataTabs = catalog?.data || {};
    const isDataNotFound = !dataTabs.length;
    if (isDataNotFound) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} // Replace with your custom image if needed
          description={
            <>
              <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                No data available
              </span>
              <br />
              <span style={{ color: 'white', fontSize: '12px' }}>
                Please check back later.
              </span>
            </>
          }
        />
      );
    }
    return Object.entries(dataTabs).map(([category, categoryData]) => {
      const categoryDataTyped = categoryData as { category: string; products: any[]; icon: string };
      const subcategory = categoryDataTyped.products;
      const objectCount = subcategory.length;
      const formattedCategory = categoryDataTyped.category;

      return (
        <div
          onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
          onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
          key={category}
          onClick={() => handleCardClick(subcategory)}
          style={{
            display: 'flex',
            // justifyContent:'space-between',
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '12px',
            marginBottom: '10px',
            marginTop: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
          }}
        >
          <img src={dataTabs[category].icon} alt={formattedCategory} style={{ width: '40px', marginRight: '10px' }} />
          <div style={{ width: "166px" }}>
            <h4 style={{ marginBottom: '10px' }}>{formattedCategory}</h4>
            <p>{objectCount} Products</p>
          </div>
          <IoChevronForwardOutline size={30} style={{ color: '#fff' }} />
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div style={{ 
       display: 'flex',
       justifyContent: 'space-around',
       padding: '8px',
       backgroundColor: '#141617',
       borderRadius: '6px',
       margin: '0 auto',}}>
        {['Data', 'Insight', 'Survey', 'Other'].map((tab) => (
          <Button
            type="text"
            key={tab}
            id={tab}
            onClick={() => handleTabClick(tab)}
            style={{
              borderRadius: '6px',
              color: activeTab === tab ? '#ffff' : '#718096',
              fontWeight: activeTab === tab ? 'bold' : 'bold',
              padding: '0 10px',
              border: activeTab === tab ? '2px solid #333939' : '#333939',
            }}
          >
            {tab}
          </Button>
        ))}
      </div>
      <div style={{ overflowY: 'auto' }}>
        {renderTabContent()}
      </div>
      {showCardCatalog && <CardCatalog selectedCategory={selectedCategory} location={location} />}
    </React.Fragment>
  );
};

