import React, { useEffect, useState } from 'react';
import { Layout, Card, Form, Input, Button, Row, Col, Avatar, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useReduxUser from '../../hoc/hooks/useUser';
import { IoWalletOutline } from "react-icons/io5";
import Text from '../../style/styledComponent/Text';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import { ModalTopUp } from '../../shared/modal';
import { auth } from '../../firebase/firebaseConfig';

const { Content } = Layout;

export const UserProfilePage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);  // Loading state for fetching user data
  const { getDetailUser, dataUser } = useReduxUser();
  const { ModalTopUpOpen, isOpenModalTopUp } = useReduxDrawer();
  const user = auth.currentUser;

  const fetchGetUser = async () => {
    if (!user?.uid) return; // Ensure user exists
    setLoading(true);
    try {
      const userId = user.uid;
      await getDetailUser(userId);  // Fetch user details
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);  // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (user?.uid) {
      fetchGetUser();  // Fetch user details when user is available
    }
    // eslint-disable-next-line 
  }, [user]);  // Trigger effect when user changes

  useEffect(() => {
    if (dataUser) {
      form.setFieldsValue({
        username: dataUser.displayName,
        email: dataUser.email,
        phoneNumber: dataUser.phoneNumber || '-',
      });
    }
  }, [dataUser, form]);  // Update form fields when dataUser changes

  const handleFinish = (values: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const onClickModalTopUp = () => {
    ModalTopUpOpen(true);
  };

  const avatarUrl = dataUser?.photoURL;

  return (
    <Layout style={{ padding: '44px', minHeight: '100vh', background: "#272B34" }}>
      <Content>
        <Spin spinning={loading}>  {/* Add loading spinner */}
          <Card style={{ marginBottom: '24px', background: "#363A45", color: 'white' }}>
            <Row gutter={16}>
              <Col span={10} style={{ textAlign: 'center' }}>
                <div style={{ color: 'white', border: '1px solid #ccc', padding: '20px', alignContent: 'center', height: '100%', borderRadius: '8px' }}>
                  <Text style={{ color: 'white', marginBottom: '10px', fontSize: '20px', fontWeight: 600 }}>Account Balance</Text>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', placeContent: 'center' }}>
                    <IoWalletOutline size={40} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
                    <Text style={{ color: 'white', fontSize: '20px' }}>{`Rp. ${dataUser?.balance} `.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Text>
                  </div>
                  <Button type="primary" onClick={onClickModalTopUp}>
                    Top Up Balance
                  </Button>
                </div>
              </Col>
              <Col span={13} style={{ textAlign: 'center' }}>
                {!user ? <Avatar size={120} icon={<UserOutlined />} /> :
                  <Avatar size={120} src={avatarUrl} />}
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleFinish}
                >
                  <Form.Item
                    name="username"
                    label={<Text>User Name</Text>}
                    rules={[{ message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={<Text>Email</Text>}
                    rules={[{ message: 'Please input your email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label={<Text>Number</Text>}
                    rules={[{ message: 'Please input your phone number!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Content>
      <ModalTopUp visibled={isOpenModalTopUp} />
    </Layout>
  );
};
