import React, { useEffect, useState } from 'react';
import {
    Button, message,
    Form, Input,
    Modal
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import logo from '../../../asesst/Icons/product/Geosquare-logo.png'; // Import your logo image
import useReduxUser from '../../../hoc/hooks/useUser';
// import { useNavigate } from 'react-router-dom';
import {
    auth,
    googleProvider,
    appleProvider,
    db
} from '../../../firebase/firebaseConfig';
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    // signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Text from '../../../style/styledComponent/Text';

interface PopupModalProps {
    visible: boolean;
}

const ModalLogin: React.FC<PopupModalProps> = ({ visible }) => {
    const [loading, setLoading] = useState(false);
    const { setUser, modalLoginClose } = useReduxUser();
    // const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [setUser]);

    const handleGoogleLogin = async () => {
        // setLoading(true);
        try {
            await signInWithPopup(auth, googleProvider);
            message.success('Login with Google successful');
            modalLoginClose(true)
        } catch (error) {
            console.error('Error logging in with Google:', error);
            message.error('Failed to login with Google');
        } finally {
            setLoading(false);
        }
    };
    const handleAppleLogin = async () => {
        // setLoading(true);
        try {
            await signInWithPopup(auth, appleProvider);
            message.success('Login with Apple successful');
            modalLoginClose(true)
        } catch (error) {
            console.error('Error logging in with Apple:', error);
            message.error('Failed to login with Apple');
        } finally {
            setLoading(false);
        }
    };
    const handleCancel = () => {
        modalLoginClose(false);
    };
    const onFinish = async (values: { email: string; password: string }) => {
        const { email, password } = values;
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDocRef = doc(db, 'usersGeosquare', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // Create new user document with default values if not exists
                await setDoc(userDocRef, {
                    email: user.email,
                    provider: 'none',
                    photoURL: user.photoURL || null,
                    phoneNumber: user.phoneNumber || null,
                    displayName: user.displayName || null,
                    name: user.displayName || null,
                    lastLogin: new Date(),
                    createdAt: new Date(),
                    balance: 0, // Set default balance or other initial values
                    total_spent: 0,
                    total_balance: 0
                });
            } else {
                // Update only the fields that need to be changed
                await setDoc(userDocRef, {
                    lastLogin: new Date(), // Update last login date
                }, { merge: true });
            }
            message.success('Login successful');
            modalLoginClose(true)
            // navigate('/'); // Redirect to home page or dashboard
        } catch (error) {
            console.error('Error logging in:', error);
            message.error('Failed to login, please check email and password');
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            open={visible}
            closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to 
            onCancel={handleCancel}
            footer={false}
            styles={{
                content: {
                    backgroundColor: '#333939'
                },
                body: {
                    backgroundColor: '#333939',
                    color: 'white'
                },
            }}
        >
            <div style={{ maxWidth: 300, margin: '0 auto', padding: '20px 0', textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ width: '200px' }} />
                <Form name="login" onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block style={{ fontFamily: '"Poppins", sans-serif', }}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                <Text style={{ color: 'white', fontFamily: '"Poppins", sans-serif', }}>Or Sign In Using</Text>
                <div style={{ marginTop: '20px', }} >
                    <FcGoogle
                        onClick={handleGoogleLogin}
                        style={{
                            marginRight: 15,
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease'
                        }}
                        size={42}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateY(-5px)')}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateY(0)')} />
                    <FaApple
                        onClick={handleAppleLogin}
                        style={{
                            marginRight: 15,
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease'
                        }}
                        size={42}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateY(-5px)')}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateY(0)')} />
                </div>
            </div>
        </Modal>
    );
};

export default ModalLogin;
