
export function filterByTimeOfDay(data: any[], timeOfDay: string) {
    const suffix = `_${timeOfDay}`; // Automatically append the suffix (e.g., "_night")
    return data.map(item => {
        const filteredItem: { [key: string]: any } = {
            gid: item.gid,  // Always include gid
            color: 'gray'  // Always include color
        };
        
        // Include keys that match the suffix (e.g., "_night")
        Object.keys(item).forEach(key => {
            if (key.endsWith(suffix)) {  // Include both the suffix and '_total'
                filteredItem[key] = item[key];
            }
        });
        return filteredItem;
    });
}
export function filterAllBySeriesName(data: any[], seriesName: string) {
    // If the seriesName is 'alltime', return the entire dataset
    // if (seriesName === 'Total') {
    //     return data;
    // }

    // Otherwise, filter by the given seriesName
    return data.filter(item => item.seriesName === seriesName);
}