import React from 'react';
import { ChatAi } from '../input/chat';

export const DrawerChatAi: React.FC<any> = ({ data }) => {
 
    return (
        <React.Fragment>
            {/* Header */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                <h2 style={{ color: 'white' }}>Omben AI</h2>
            </div> 
          <ChatAi/>
        </React.Fragment>
    );
};
