import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { CardListMyDataMobile } from '../../../shared/map/card/mobile/cardMyData.mobile';

export const CardMyDataMobile: React.FC<any> = ({ data }) => {
  const [topPosition, setTopPosition] = useState(250); // Default top position
  const [height, setHeight] = useState(window.innerHeight); // Default to current screen height

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Update height
      setHeight(screenHeight);

      // Jika layar ponsel (<= 480px), set top position berbeda
      if (screenWidth <= 480) {
        setTopPosition(screenHeight * 0.10); // Misal, 15% dari tinggi layar
      } else {
        setTopPosition(screenHeight * 0.25); // Misal, 25% dari tinggi layar untuk layar lebih besar
      }
    };

    // Run on mount and when resizing
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Space
        direction="horizontal"
        style={{
          position: 'absolute',
          top: topPosition,
          transition: '0.25s ease-in-out',
          height: height * 0.5, // Set height as 50% of screen height, adjust as needed
          overflowY: 'auto', // Allows scrolling if content is taller than container
        }}
      >
        <CardListMyDataMobile dataCard={data?.data} />
      </Space>
    </React.Fragment>
  );
};
