import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { FiEyeOff, FiEye } from "react-icons/fi";
import { setLayerOpacity } from '../../../../component/newmap/helper/map/layer.opacity';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import { sortArray } from '../../../../component/newmap/helper/short';
import { formatNumberSqm } from '../../../../component/newmap/helper/formatNumber';

const { Text } = Typography;

interface DataLayerGroup {
  columnName: string;
  productName: string;
  subdataName: string;
  additinal: any;
  combinedName: string;
  seriesName: string;
  isSeries: boolean;
  bins: number[];
  min: number;
  max: number;
}

interface DrawerProps {
  dataLayerGroup: DataLayerGroup[];
  map: maplibregl.Map;
}

export const DrawerGroupLayerMobile: React.FC<DrawerProps> = ({ dataLayerGroup, map }) => {
  const { colorGroup } = useReduxCatalog();
  // State to handle layer visibility
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});

  // When dataLayerGroup changes, update the visibility state
  useEffect(() => {
    const initialVisibility: Record<string, boolean> = {};
    dataLayerGroup.forEach(layer => {
      initialVisibility[layer.columnName] = true; // Visible by default
    });
    setVisibility(initialVisibility);
  }, [dataLayerGroup]);

  const [opacity] = useState<number>(80); // Default opacity set to 50%

  const toggleVisibility = (item: DataLayerGroup) => {
    const newVisibility = { ...visibility, [item.columnName]: !visibility[item.columnName] };
    setVisibility(newVisibility);

    const layerOpacity = newVisibility[item.columnName] ? opacity / 100 : 0;
    setLayerOpacity(map, 'layer', item.columnName, layerOpacity);
  };

  return (
  
      <div
        style={{
          background: '#142942',
          border: '1px solid #d9d9d9',
          color: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          width: 'auto',
          height: 'auto',
          maxHeight: '380px', // Set your desired max height
          overflowY: 'scroll', // Enable vertical scrolling
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'sticky', top: 0, background: '#142942', padding: '8px', zIndex: 101 }}>
          <Text style={{ width: '165px', color: 'white', fontWeight: 'bolder' }}>Sub Data</Text>
        </div>
        {dataLayerGroup && dataLayerGroup.map((layer, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px' }}>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '3px',
                  background: `linear-gradient(0deg,${colorGroup?.color})`,
                  marginRight: '8px',
                }}
              ></div>
              <Text style={{ width: '290px', color: 'white', fontWeight: 'bold' }}>
                {layer.subdataName || layer.productName}
              </Text>
              <div
                onClick={() => toggleVisibility(layer)}
                style={{ marginLeft: '17px', cursor: 'pointer' }}
              >
                {visibility[layer.columnName] ? <FiEye size={17} /> : <FiEyeOff size={17} />}
              </div>
            </div>

            {visibility[layer.columnName] && (
              <div style={{ paddingLeft: '30px', marginTop: '5px', overflowY: 'scroll' }}>
                {/* Check if layer.bins is an array before sorting */}
                {Array.isArray(layer?.bins) && sortArray(layer?.bins).map((bin, binIndex, sortedBins) => {
                  const nextBin = sortedBins[binIndex + 1];
                  if (nextBin === undefined) return null;

                  let binColor = '';
                  if (colorGroup?.type === 'gradient') {
                    const colors = colorGroup?.color || [];
                    const colorIndex = binIndex < colors.length ? binIndex : colors.length - 1;
                    binColor = colors[colorIndex];
                  } else {
                    const colors = colorGroup?.color || [];
                    const colorIndex = Math.min(binIndex, colors.length - 1);
                    binColor = colors[colorIndex];
                  }

                  return (
                    <div key={binIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          borderRadius: '5px',
                          backgroundColor: binColor,
                          marginRight: '8px',
                        }}
                      ></div>
                      <Text style={{ color: 'white' }}>
                        {formatNumberSqm(bin)} - {formatNumberSqm(nextBin)}
                      </Text>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}

      </div>
  );
};
