import React, { useEffect } from 'react';
import { Space, Empty } from 'antd';
import { DrawerMyChart } from '../../shared/map/drawer/drawer.chart';
import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { v4 as uuidv4 } from 'uuid';
import noData from '../../asesst/new/nodata.svg'; // Replace with your image path
import { auth } from '../../firebase/firebaseConfig';
import iconHeader from '../../asesst/Icons/interactivemap/icon.svg';
import useReduxUser from '../../hoc/hooks/useUser';
import ModalLogin from '../../shared/map/modal/modal-login';
interface MenuCatalogProps {
    onClose: () => void;
}

export const MenuChartCatalog: React.FC<MenuCatalogProps> = ({ onClose }) => {
    const { listCart, setListCart } = useReduxCatalog();
    const {isLoginModalVisible, modalLoginOpen } = useReduxUser();

    const user = auth.currentUser; // Get the current user

    useEffect(() => {
        const fetchData = async () => {
            if (user) {  // Only fetch data if the user exists
                try {
                    const response = await setListCart();  // Fetch the cart data
                    if (response?.statusCode !== 200) {
                        console.error('Error fetching data:', response);
                    }
                } catch (error) {
                    console.error('Error occurred during data fetch:', error);
                }
            }
        };

        fetchData();  // Call the async function to fetch the data
        // eslint-disable-next-line
    }, [user]);  // Include user as a dependency to refetch when the user state changes

    // Flatten and add unique IDs to the data
    const groupedData = listCart?.data?.flatMap((item: { dataIds: any[]; polygon_id: any }) =>
        item.dataIds.map((dataId: any) => ({
            polygon_id: item.polygon_id,
            ...dataId,
            id: uuidv4(), // Adding a unique ID to each object
        }))
    );

    // Conditional rendering based on whether listCart is empty or not
    const isDataNotFound = !listCart?.data?.length;

    const handleLoginModalOpen = () => {
        modalLoginOpen(true);
      };
    
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '120px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#333939',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '10px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    {!user ? ( // Show this block if the user is not logged in
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                color: 'white',
                                marginTop: '100px',
                            }}
                        >
                               <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                        <img src={iconHeader} alt="No message" style={{ width: '100px', height: '200px' }} />
                        <p style={{ color: 'white', fontSize: '16px' }}> You need to be logged in to access this section.</p>
                    </div>
                            <button
                            onClick={handleLoginModalOpen}
                                style={{
                                    marginTop:'30px',
                                    backgroundColor: '#4A7FC0',
                                    color: '#fff',
                                    width: '100%',
                                    fontWeight:400,
                                    padding: '10px 16px',
                                    borderRadius: '8px',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                             Go to Login
                            </button>
                        </div>
                    ) : isDataNotFound ? (
                        <div
                            style={{
                                display: 'flex',
                                height: '150px',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <Empty
                                image={noData}
                                description={
                                    <>
                                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                                            There is no data in the cart yet
                                        </span>
                                        <br />
                                        <span style={{ color: 'white', fontSize: '12px' }}>
                                            Explore the catalog to see the data you need.
                                        </span>
                                    </>
                                }
                            />
                        </div>
                    ) : (
                        <>
                            <DrawerMyChart data={groupedData} />
                            <ToogleMenu onClose={onClose} />
                        </>
                    )}
                </div>
            </Space>
            <ModalLogin
	               visible={isLoginModalVisible}
				/>
        </React.Fragment>
    );
};
