import { Space } from 'antd';
import React from 'react';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

const Legendinsight = () => {
    const { colorGroup } = useReduxCatalog();

    return (
        <Space
            direction="vertical"
            style={{
                position: 'absolute',
                right: '10px',
                top: 238,
                width: '235px',
                zIndex: 100,
                transition: '0.25s ease-in-out',
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '8px',
                alignItems: 'center',
                color: 'white',
                padding:'10px',
                height: '100px',
                fontFamily: 'Arial, sans-serif',
                background: '#333939',
                border: '1px solid #d9d9d9',
            }}>
                <span style={{ fontSize: '14px', marginBottom: '4px',fontWeight: 'bold' ,placeSelf:'start' }}>Legend</span>
                <div style={{
                    width: '200px',
                    height: '30px',
                    background:`linear-gradient(to right, 
                        ${colorGroup?.color?.[0]} 0%, 
                        ${colorGroup?.color?.[1]} 12.5%, 
                        ${colorGroup?.color?.[2]} 25%, 
                        ${colorGroup?.color?.[3]} 37.5%, 
                        ${colorGroup?.color?.[4]} 50%, 
                        ${colorGroup?.color?.[5]} 62.5%, 
                        ${colorGroup?.color?.[6]} 75%, 
                        ${colorGroup?.color?.[7]} 87.5%, 
                        ${colorGroup?.color?.[8]} 93%, 
                        ${colorGroup?.color?.[9]} 100%)`,
                    borderRadius: '4px',
                    position: 'relative',
                }}>
                    <span style={{
                        position: 'absolute',
                        left: '0',
                        bottom: '-18px',
                        fontSize: '12px',
                        color: 'white',
                        fontWeight: 'bolder' 
                    }}>Unfavorable</span>
                    <span style={{
                        position: 'absolute',
                        right: '0',
                        bottom: '-18px',
                        fontSize: '12px',
                        color: 'white',
                        fontWeight: 'bolder' 
                    }}>Favorable</span>
                </div>
            </div>
        </Space>
    );
};

export default Legendinsight;
