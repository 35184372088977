import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import LoaderSideBar from '../../../loader/loader.sidebar';
import mapid from "../../../../asesst/Icons/product/mapid.png";

interface SubmenuFormProps {
    onBack: () => void; // A function prop for going back to the main menu
}

const CatalogMenuMobile: React.FC<SubmenuFormProps> = ({ onBack }) => {
    const [activeTab, setActiveTab] = useState('Data');
    // const [showCardCatalog, setShowCardCatalog] = useState(false);
    const {
        setCatalog,
        setSelectedCategory,
        setShowCardCatalog,
        paramsCatalogGeometry,
        catalog,
        isloading,
        location,
    } = useReduxCatalog();
// console.log(selectedCategory);

    const [param, setParam] = useState('data');
    // const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

    const handleCardClick = (category: any) => {
        setSelectedCategory(category);
        setShowCardCatalog(true);
    };

    useEffect(() => {
        setCatalog(param, paramsCatalogGeometry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param, paramsCatalogGeometry, location]);

    const renderTabContent = () => {
        if (!paramsCatalogGeometry || !catalog) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <>
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                                Please Search Location First To Get Catalog
                            </span>
                            <br />
                            
                        </>
                    }
                />
            );
        }
    
        if (activeTab === 'Other') {
            return (
                <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px', gap: '10px' }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        <img
                            alt="example"
                            src={mapid}
                            style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: '8px' }} />
                    </span>
                    <span style={{ fontSize: 12, lineHeight: '1.5' }}><b>MAPID</b> is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.</span>
                </div>
            );
        }

        if (isloading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <LoaderSideBar />
                </div>
            );
        }

        const dataTabs = catalog?.data || {};
        const isDataNotFound = !dataTabs.length;

        if (isDataNotFound) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <>
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize: '14px' }}>
                                No data available
                            </span>
                            <br />
                            <span style={{ color: 'white', fontSize: '12px' }}>
                                Please check back later.
                            </span>
                        </>
                    }
                />
            );
        }

        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr', // Two columns
                gap: '10px',
            }}>
                {Object.entries(dataTabs).map(([category, categoryData]) => {
                    const categoryDataTyped = categoryData as { category: string; products: any[]; icon: string };
                    const subcategory = categoryDataTyped.products;
                    const objectCount = subcategory.length;
                    const formattedCategory = categoryDataTyped.category.replace(/_/g, ' ').toLowerCase();

                    return (
                        <div
                            key={category}
                            onClick={() => handleCardClick(subcategory)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                background: '#142943',
                                padding: '10px',
                                borderRadius: '12px',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            }}
                        >
                            <img src={dataTabs[category].icon} alt={formattedCategory} style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                            <h4 style={{ marginBottom: '5px', fontSize: '12px', textAlign: 'center', color: 'white' }}>{formattedCategory}</h4>
                            <p style={{ fontSize: '10px', color: 'white', textAlign: 'center' }}>{objectCount} Products</p>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleTabClick = (params: string) => {
        setActiveTab(params);
        const paramCatalog = params.toLowerCase();
        setParam(paramCatalog);
    };

    return (
        <div style={{ backgroundColor: '#1E334D', color: 'white' }}>
            <div
                style={{
                    position: 'sticky',
                    top: '-20px',
                    backgroundColor: '#1E334D',
                    zIndex: 1,
                }}
            >
                <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                    <span
                        onClick={onBack}
                        style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                        }}
                    >
                        ← Result
                    </span>
                </div>

                {/* List Items */}
                <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
                    {['Data', 'Insight', 'Survey', 'Other'].map((tab) => (
                        <Button
                            type="text"
                            key={tab}
                            id={tab}
                            onClick={() => handleTabClick(tab)}
                            style={{
                                borderRadius: '0%',
                                color: activeTab === tab ? '#4A7CF3' : 'white',
                                fontWeight: activeTab === tab ? 'bold' : 'normal',
                                padding: '0 10px',
                                borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
                            }}
                        >
                            {tab}
                        </Button>
                    ))}
                </div>
            </div>

            {/* Scrollable content */}
            <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                {renderTabContent()}
            </div>
        </div>
        
    );
};

export default CatalogMenuMobile;
