import React from 'react';
import iconCatalog from '../../../asesst/Icons/interactivemap/maps.svg';
import iconAi from '../../../asesst/Icons/interactivemap/ai.svg';
import iconChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import iconLayer from '../../../asesst/Icons/interactivemap/mydata.svg';
import BottomDrawerMenu from '../../../shared/map/drawer/mobile/drawer.bottom.menu';

interface SideMenuProps {
  map: maplibregl.Map | null; // Pass the map instance as a prop
}

export const MobileMenu: React.FC<SideMenuProps> = ({map}) => {

  const menuItems = [
    {
      icon: iconCatalog,
      label: 'Catalogue',
      subMenu: [
        { icon: 'path/to/icon1-1.png', label: 'SubMenu 1-1' },
        { icon: 'path/to/icon1-2.png', label: 'SubMenu 1-2' },
      ],
    },
    {
      icon: iconLayer,
      label: 'My Data',
      subMenu: [
        { icon: 'path/to/icon2-1.png', label: 'SubMenu 2-1' },
        { icon: 'path/to/icon2-2.png', label: 'SubMenu 2-2' },
      ],
    },
    {
      icon: iconAi,
      label: 'Chat wiht AI',
      subMenu: [
        { icon: 'path/to/icon2-1.png', label: 'SubMenu 2-1' },
        { icon: 'path/to/icon2-2.png', label: 'SubMenu 2-2' },
      ],
    },
    {
        icon: iconChart,
        label: 'Cart',
        subMenu: [
          { icon: 'path/to/icon2-1.png', label: 'SubMenu 2-1' },
          { icon: 'path/to/icon2-2.png', label: 'SubMenu 2-2' },
        ],
      },
  ];
  
  return (
    <>
      <BottomDrawerMenu map={map} menuItems={menuItems} />
    </>
  );
};
