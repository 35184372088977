import React from 'react';
// import { ChatAiMobile } from '../../input/mobile/chat.mobile';
import iconHeader from '../../../../asesst/Icons/interactivemap/icon.svg';
export const DrawerChatAiMobile: React.FC<any> = ({ onBack }) => {
 
    return (
        <React.Fragment>
            {/* Header */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                    <span
                        onClick={onBack}
                        style={{
                            color:'white',
                            fontSize: '18px',
                            fontWeight: 'bold',
                        }}
                    >
                        ← Omben AI
                    </span>
                </div>
                <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                        <img src={iconHeader} alt="No message" style={{ width: '100px', height: '200px' }} />
                        <p style={{ color: 'white', fontSize: '16px' }}>Omben is currently cooking something delicious!</p>
                    </div>
          {/* <ChatAiMobile/> */}
        </React.Fragment>
    );
};
