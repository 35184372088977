/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import bgImage from '../../asesst/new/bg.png';
import checkimg from '../../asesst/new/img/check.svg';
import useReduxCatalog from "../../hoc/hooks/useCatalog";
import { useNavigate } from 'react-router-dom';
import { formatNumberWithDots } from "../../component/newmap/helper/formatNumber";
import { formatDate } from "../../component/newmap/helper/date";

function SuccesPayment() {
    const { invoice_id, setInvoiceId } = useReduxCatalog();
    const invoiceId = localStorage.getItem('invoiceId');
    const navigate = useNavigate();

    useEffect(()=>{
        setInvoiceId(invoiceId)
        // eslint-disable-next-line
    },[])

    const handleToCatalog = () => {
        navigate('/map');
    };
    const handleToDashboard = () => {
        navigate('/map');
    };
    return (
        <div
            style={{
                backgroundImage: `url(${bgImage})`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width:'100%'
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundColor: '#1E334D',
                    borderRadius: '12px',
                    padding: '20px',
                    width: '400px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '50px',
                    }}
                >
                 {/* <IoCheckmark size={50} color="@1E334D"/> */}
                 <img src={checkimg} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                </div>
                <h2 style={{ fontSize: '24px', margin: '0 0 8px' }}>Payment Success!!</h2>
                <p style={{ fontSize: '14px', margin: '0 0 20px' }}>
                    Your payment has been successfully done.
                </p>
                <h1 style={{ fontSize: '32px', margin: '0 0 16px' }}>
                    Rp {formatNumberWithDots(invoice_id?.amount)}
                </h1>
                <div
                    style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        marginBottom: '50px',
                        color: '#ddd',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Reff number</strong>
                        <span style={{ textAlign: 'right' }}>{invoice_id?.id}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Payment time</strong>
                        <span style={{ textAlign: 'right' }}>{formatDate(invoice_id?.created)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Payment method</strong>
                        <span style={{ textAlign: 'right' }}>{invoice_id?.paymentMethod}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Account email</strong>
                        <span style={{ textAlign: 'right' }}>{invoice_id?.payerEmail}</span>
                    </div>
                </div>

                <p style={{ fontSize: '14px', marginBottom: '20px', fontWeight:400, lineHeight:'18px' }}>
                    Data on the GeoSquare platform is available on the My Data menu. Raw
                    data will be sent within 48 hours to your email and dashboard.
                </p>
                <div
                    style={{
                        display: 'flex',
                        gap:'10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <button
                        onClick={handleToCatalog}
                        style={{
                            backgroundColor: 'transparent',
                            color: 'white',
                            padding: '10px 16px',
                            fontWeight:400,
                            borderRadius: '8px',
                            width: '180px',
                            border: '1px solid white',
                            cursor: 'pointer',
                        }}
                    >
                        Explore Other Data
                    </button>
                    <button
                     onClick={handleToDashboard}
                        style={{
                            backgroundColor: '#4A7FC0',
                            color: '#fff',
                            width: '180px',
                            fontWeight:400,
                            padding: '10px 16px',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Go to My Data
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SuccesPayment;
