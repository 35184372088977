import Container from "../style/styledComponent/Container";
import { NavBar } from "../component/page/shared/nav";
// import SectionHero from "../layout/pageLayout/component/componentHome/section.hero";
// import SectionService from "../layout/pageLayout/component/componentHome/section.services";
import SectionEasyAccess from "../component/page/component/componentHome/section.easyAccess";
import SectionOneApi from "../component/page/component/componentHome/section.oneApi";
// import SectionOpenData from "../layout/pageLayout/component/componentHome/section.openData";
import SectionFooters from "../component/page/shared/footer/footer.section";
import SectionOurPartners from "../component/page/component/componentHome/section.ourPartners";
import SectionServices2 from "../component/page/component/componentHome/section.services.new";
import TalkToUsSection from "../component/page/component/componentHome/section.taklToUs";
import SectionDataPublic from "../component/page/component/componentHome/section.dataPublic";
import SectionGranurall from "../component/page/component/componentHome/section.granural";
// import SectionHeroMerdeka from "../component/page/component/componentHome/section.hero.merdeka";
import SectionDataPublicMobile from "../component/page/component/componentHome/mobile/section.dataPublic.mobile";
import SectionGranullarMobile from "../component/page/component/componentHome/mobile/section.granular.mobile";
import { useEffect, useState } from "react";
import SectionEasyAccessMobile from "../component/page/component/componentHome/mobile/section.easyAccess.mobile";
import SectionApiCallMobile from "../component/page/component/componentHome/mobile/section.oneApi.mobile";
import SectionOurPartnersMobile from "../component/page/component/componentHome/mobile/section.ourPartner.mobile";
import TalkToUsSectionMobile from "../component/page/component/componentHome/mobile/section.talkToUs.mobile";
// import SectionHeroMerdekaMobile from "../component/page/component/componentHome/mobile/section.merdeka.mobile";
import SectionHero from "../component/page/component/componentHome/section.hero";
import SectionHeroMobile from "../component/page/component/componentHome/mobile/section.hero.mobile";


function LandingPage() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
    
            <Container id='wrapper' data-testid="app" css={{
                "@bp1": {
                    padding: " $2 $3",
                },
                "@bp2": {
                    padding: " $2 $5",
                },
                "@bp5": {
                    padding: " $2 $3",
                },
                backgroundSize: "cover",
                backgroundColor:'white',
                overflowY: "scroll", // Enable vertical scroll
                "&::-webkit-scrollbar": {  // Hide scrollbar for Chrome and Safari
                    width: "0px",
                    background: "transparent",
                    display: "none",
                  },
            }}>
            
            <NavBar style={{ background: "#142942", top: '20px', zIndex: 1000, height: windowWidth <= 768 ? "60px" : "84px", width: windowWidth <= 768 ? "380px" : '1300px'}} />
            { windowWidth <= 768 ? <SectionHeroMobile/> : <SectionHero/> } 
            {/* { windowWidth <= 768 ? <SectionHeroMerdekaMobile/> : <SectionHeroMerdeka/> } */}
            { windowWidth <= 768 ? '' : <SectionServices2/> }
            {/* <SectionEasyAccess/> */}
            { windowWidth <= 768 ? <SectionEasyAccessMobile/> : <SectionEasyAccess/> }
            { windowWidth <= 768 ? <SectionApiCallMobile/> : <SectionOneApi/> }
            { windowWidth <= 768 ? <SectionDataPublicMobile/> : <SectionDataPublic/> }
            { windowWidth <= 768 ? <SectionGranullarMobile/> : <SectionGranurall/> }
            { windowWidth <= 768 ? <SectionOurPartnersMobile/> : <SectionOurPartners/> }
            { windowWidth <= 768 ? <TalkToUsSectionMobile/> : <TalkToUsSection/> }
            <SectionFooters/>
            </Container>
        </>
    );
}

export default LandingPage;
