export const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',  // Menambahkan jam
        minute: '2-digit', 
    });
};

export const formatDateOnlyMonth = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString('en-GB', {
        // day: 'numeric',
        month: 'long',
        year: 'numeric',
        // hour: '2-digit',  // Menambahkan jam
        // minute: '2-digit', 
    });
};
