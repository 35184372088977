import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, message, notification, Slider, Select } from 'antd';
import { v4 as uuidv4 } from 'uuid';
// import { EnvironmentOutlined, AimOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icChart from '../../../asesst/Icons/interactivemap/icon/cart-add.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
// import Text from '../../../style/styledComponent/Text';
import { LoadingOutlined } from '@ant-design/icons';
import {
    auth,
} from '../../../firebase/firebaseConfig';
import useReduxPayment from '../../../hoc/hooks/usePayment';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import ModalLogin from '../modal/modal-login';
import useReduxUser from '../../../hoc/hooks/useUser';
// import { LiaCoinsSolid } from "react-icons/lia";
import { ModalWalletMethode } from './modal-wallet-method';
import { BankOutlined, WalletOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
// import { ModalConfirmPayment } from './modal-confirm-payment';

interface ModalChecoutProps {
    isModal: boolean,
    data: any
}
interface AdditionalProps {
    size: any,
}

interface SubdataSelection {
    product: string;
    size_grid: number;
    subdata: string[];
    additional: AdditionalProps;
}

interface DataSelection {
    polygon_id: string;
    dataIds: SubdataSelection[];
    centroid: any[];
    address: string;
    total_grid: number;
    area: number;
}
interface CheckoutInterface {
    uid: string;
    name: string;
    email: string;
    number: string;
    order_id: string;
    dataItems: DataSelection[];
    type: string;
    status_payment: string;
    status_data: string;
    output_status: string;
    created_at: string;
    deleted_at: string;
    updated_at: string;
    externalId: string;
    payerEmail: string;
    description: string;
    currency: string;
    invoiceDuration: number;
}
export const ModalDefault: React.FC<ModalChecoutProps> = ({ isModal, data }) => {
    const [radius, setRadius] = useState(0); // Initial radius set to 0
    const {
        setModalCloseCheckout,
        setChart,
        setSelectedItem,
        setModalWalletPayment,
        modalWalletPayment } = useReduxCatalog();
    const { isLoginModalVisible, modalLoginOpen, getDetailUser, dataUser } = useReduxUser();
    const { setPaymentv2 } = useReduxPayment();
    const [loading, setLoading] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('bank');

    const [selectedItems, setSelectedItems] = useState<DataSelection>({
        polygon_id: '', // Dynamically get polygon_id
        centroid: [],
        address: '',
        total_grid: 0,
        area: 0,
        dataIds: [{
            size_grid: 0,
            product: '',
            subdata: [],
            additional: {
                size: null
            }
        }],
    });
    // console.log(selectedPaymentMethod);

    const user = auth.currentUser;
    const [paymentCheckout, setPaymentCheckout] = useState<CheckoutInterface | null>(null);

    useEffect(() => {
        const uuid = uuidv4();
        const modifiedUuid = uuid.toUpperCase().replace('-', ''); // Hapus tanda '-' yang pertama
        const invUid = modifiedUuid.slice(0, 12)
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Get current month (add 1 because getMonth() returns 0-11)
        const year = now.getFullYear(); // Get current year
        const invoiceId = `INV-GE0-${invUid}-${month}-${year}`;
        // console.log(user);

        setPaymentCheckout({
            uid: user?.uid || "",
            name: user?.displayName || '',
            email: user?.email || '',
            number: `${user?.phoneNumber || 0}`,
            order_id: invoiceId,
            dataItems: Array.isArray(selectedItems) ? selectedItems : [selectedItems], // Ensure it's an array
            type: data?.typeDataId,
            status_payment: "waiting",
            status_data: "waiting",
            output_status: "available",
            created_at: now.toISOString(),
            deleted_at: "",
            updated_at: "",
            externalId: invoiceId,
            payerEmail: user?.email || "",
            description: data?.title,
            currency: "IDR",
            invoiceDuration: 86400, // Assuming this is correct
        });


        // eslint-disable-next-line
    }, [selectedItems, user]);
    //   console.log(paymentCheckout);
    const handleSubmit = async () => {
        const user = auth.currentUser;
        if (!user) {
            // Show notification if user is not authenticated
            notification.warning({
                style: {
                    backgroundColor: '#142943',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Authentication is required</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Please login first before proceeding to checkout.</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
            modalLoginOpen(true);
            return; // Stop execution if the user is not logged in
        }

        setLoading(true); // Set loading to true
        try {
            const response = await setPaymentv2(paymentCheckout);
            if (response && response.success) {
                if (response.data === null) {
                    handleCancel(); // Close the modal
                    notification.success({
                        style: {
                            backgroundColor: '#142943',
                            color: 'white'  // Menjadikan teks berwarna putih
                        },
                        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Successful</p>,
                        description: <p style={{ color: 'white', fontWeight: 'bold' }}>Successfully Please check your DATA for further actions.</p>,
                        placement: 'topRight',
                    });
                } else {
                    window.open(response.data, '_blank'); // Open invoice in a new tab
                    localStorage.setItem('invoiceId', response?.invoice_id); // Store invoice ID
                    window.dispatchEvent(new Event('storage')); // Memaksa event storage agar dipicu di halaman lain
                }
            }
        } catch (error) {
            // Handle errors during payment checkout
            notification.error({
                style: {
                    backgroundColor: '#142943',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        } finally {
            setLoading(false); // Set loading to false after the operation completes
            handleCancel(); // Close the modal
            // setOpenModalConfirmPayment(true); // Open payment confirmation modal
        }
    };

    const handleAddToChart = async () => {
        const user = auth.currentUser;
        if (!user) {
            // message.warning('Please login first before proceeding to checkout.');
            notification.warning({
                style: {
                    backgroundColor: '#142943',
                    color: 'white'  // Menjadikan teks berwarna putih
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Authentication is required</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Please login first before proceeding to checkout.</p>,
                placement: 'topRight',
            });
            return; // Stop execution if the user is not authenticated
        }

        try {
            const token = await user.getIdToken(); // Only get token if user exists

            const response = await setChart(token, selectedItems);
            if (response) {
                if (response.statusCode === 201) {
                    notification.success({
                        style: {
                            backgroundColor: '#142943',
                            color: 'white'  // Menjadikan teks berwarna putih
                        },
                        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Successful</p>,
                        description: <p style={{ color: 'white', fontWeight: 'bold' }}>Successfully added data to the cart. Please check your cart for further actions.</p>,
                        placement: 'topRight',
                    });
                }
                handleCancel();
            }
        } catch (error) {
            console.error('Failed to checkout data');
            // Optionally show an error message to the user
            message.error('Failed to checkout data. Please try again.');
        }
    };
    useEffect(() => {
        if (data?.polygonId) {
            const productName = data.productId;
            setSelectedItems(prevState => ({
                ...prevState,
                polygon_id: data?.polygonId,
                centroid: [data?.centeroid?.longitude, data?.centeroid.lattitude],
                address: data?.address,
                total_grid: data?.totalpixel,
                area: data?.areaSize,
                dataIds: prevState.dataIds.map(item => ({
                    ...item,
                    size_grid: data?.size_grid,
                    product: productName,
                    additional: {
                        size: radius, // Ambil nilai size dari radius
                    }
                })),
            }));
        }
    }, [data, radius]);

    const handleCancel = () => {
        setModalCloseCheckout(false);
        setSelectedItem(null)
        setRadius(0)
        setSelectedItems({
            polygon_id: '', // Reset ke nilai default
            centroid: [],
            address: '',
            total_grid: 0,
            area: 0,
            dataIds: [{
                size_grid: 0,
                product: '',
                subdata: [],
                additional: {
                    size: null
                }
            }],
        });
    };
    const fetchGetUser = async () => {
        if (!data?.uid) return; // Ensure user exists
        setLoading(true);
        try {
            const userId = data.uid;
            await getDetailUser(userId);  // Fetch user details
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setLoading(false);  // Set loading to false after fetching
        }
    };

    useEffect(() => {
        if (data?.uid) {
            fetchGetUser();  // Fetch user details when user is available
        }
        // eslint-disable-next-line 
    }, [data?.uid]);  // Trigger effect when user changes

    const handleOpenWallet = () => {
        setModalWalletPayment(true)
    };

    const handleChangePaymentMethode = (value: any) => {
        setSelectedPaymentMethod(value);
    };
    const handleCheckboxChange = (index: number) => {
        const subdataId = data.subData[index].subdata_id; // Use subdata_id for selection
        const productName = data.productId;
        setSelectedItems((prevSelectedItems) => {
            const productIndex = prevSelectedItems.dataIds.findIndex(item => item.product === productName);

            if (productIndex > -1) {
                const existingProduct = prevSelectedItems.dataIds[productIndex];

                if (existingProduct.subdata.includes(subdataId)) {
                    const updatedSubdata = existingProduct.subdata.filter(sub => sub !== subdataId);

                    if (updatedSubdata.length === 0) {
                        const updatedDataIds = prevSelectedItems.dataIds.filter((_, i) => i !== productIndex);
                        return { ...prevSelectedItems, dataIds: updatedDataIds };
                    } else {
                        const updatedDataIds = prevSelectedItems.dataIds.map((item, i) =>
                            i === productIndex
                                ? { ...item, subdata: updatedSubdata, additional: { size: null } } // Update existing product
                                : item
                        );
                        return { ...prevSelectedItems, dataIds: updatedDataIds };
                    }
                } else {
                    const updatedDataIds = prevSelectedItems.dataIds.map((item, i) =>
                        i === productIndex
                            ? { ...item, subdata: [...item.subdata, subdataId], additional: { size: null } } // Add new subdata
                            : item
                    );
                    return { ...prevSelectedItems, dataIds: updatedDataIds };
                }
            } else {
                return {
                    ...prevSelectedItems,
                    dataIds: [
                        ...prevSelectedItems.dataIds,
                        { size_grid: data?.size_grid, product: productName, subdata: [subdataId], additional: { size: null } }, // Add new product with additional
                    ],
                };
            }
        });
    };

    const handleSelectAll = () => {
        const productName = data.productId;
        const allSubdataIds = data.subData.map((item: any) => item.subdata_id); // Map using subdata_id

        setSelectedItems((prevSelectedItems) => {
            const productIndex = prevSelectedItems.dataIds.findIndex(item => item.product === productName);

            if (productIndex > -1 && prevSelectedItems.dataIds[productIndex].subdata.length === allSubdataIds.length) {
                // If all subdata is already selected, deselect all by removing the product
                const updatedDataIds = prevSelectedItems.dataIds.filter((_, i) => i !== productIndex);
                return { ...prevSelectedItems, dataIds: updatedDataIds };
            } else if (productIndex > -1) {
                // Update the existing product with all subdataIds
                const updatedDataIds = prevSelectedItems.dataIds.map((item, i) =>
                    i === productIndex ? { ...item, subdata: allSubdataIds, additional: { size: null } } : item
                );
                return { ...prevSelectedItems, dataIds: updatedDataIds };
            } else {
                // Add new product with all subdataIds and additional size
                return {
                    ...prevSelectedItems,
                    dataIds: [...prevSelectedItems.dataIds, { size_grid: data?.size_grid, product: productName, subdata: allSubdataIds, additional: { size: null } }],
                };
            }
        });
    };

    const totalItems = data?.subData.length > 0 ? selectedItems.dataIds[0]?.subdata.length : 1;

    const totalPrices = data?.subData.length > 0
        ? selectedItems.dataIds.reduce((sum, product) => {
            const selectedSubdataPrices = product?.subdata.reduce((subSum, subdataId) => {
                const subDataItem = data?.subData.find((item: any) => item?.subdata_id === subdataId);
                return subSum + (subDataItem?.price?.price || 0);
            }, 0);
            return sum + selectedSubdataPrices;
        }, 0)
        : data?.price; // Use data.price if subData is empty

    const formattedTotalPrice = totalPrices?.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });


    // console.log(radius);

    // Check if radius is valid (not null, undefined, or an empty array)
    const isRadiusValid = data?.radius !== null && data?.radius !== undefined && data?.radius.length !== 0;


    useEffect(() => {
        if (isRadiusValid) {
            setRadius(data?.radius?.[0]); // Set the radius from data
        }
    }, [data, isRadiusValid]);

    const handleSliderChange = (value: React.SetStateAction<any>) => {
        setRadius(value); // Update radius based on slider movement
    };

    const formatRadius = (value: any) => {
        return value >= 1000 ? `${value / 1000} KM` : `${value} M`;
    };


    return (
        <>
            <Modal
                styles={{
                    content: {
                        backgroundColor: '#333939'
                    },
                    body: {
                        backgroundColor: '#333939',
                        color: 'white',
                        height: 'calc(100vh - 50px)',
                    },
                }}
                open={isModal}
                width={480}
                footer={false}
                style={{ top: '3%' }}
                onCancel={handleCancel}
                okText="Buy Now"
                cancelText="Cancel"
                closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
            >

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ flex: 1 }}>
                        <span style={{
                            fontSize: '24px',
                            marginBottom: '5px',
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            fontWeight: 'bold'
                        }}>{data?.title}</span>
                        <span style={{
                            fontSize: '17px',
                            marginBottom: '5px',
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            fontWeight: 'bold'
                        }}>Detail Location</span>
                        {/* <div
                        style={{
                            border: '1px solid white',
                            background: '#141617',
                            paddingLeft: '15px',
                            paddingTop: '10px',
                            width: '432px',
                            height: '80px',
                            borderRadius: '12px',
                            marginBottom: '10px',
                        }}
                    >
                        <EnvironmentOutlined style={{
                            fontSize: '18px',
                            marginRight: '10px'
                        }} />
                        <span style={{
                            fontWeight: 'bold',
                            fontSize: '12px'
                        }}>Location Address</span>
                        <p style={{ fontSize: '12px' }}>
                            {data?.address}
                        </p>
                    </div> */}
                        {/* <div
                        style={{
                            border: '1px solid white',
                            background: '#141617',
                            paddingLeft: '15px',
                            paddingTop: '10px',
                            height: '70px',
                            width: '432px',
                            borderRadius: '12px',
                            marginBottom: '10px',
                        }}
                    >
                        <AimOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                        <span style={{
                            fontWeight: 'bold',
                            fontSize: '12px'
                        }}>Position</span>
                        <p style={{ fontSize: '14px' }}>
                            {data?.centeroid?.lattitude} , {data?.centeroid?.longitude}
                        </p>
                    </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5px', maxWidth: '432px' }}>
                            <div
                                style={{
                                    // border: '1px solid white',
                                    background: '#141617',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    fontSize: '12px',
                                    width: '350px',
                                    height: '60px',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                    marginBottom: '10px',
                                }}
                            >
                                <img
                                    src={icAreaSize}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{ color: '#DCE1E7', fontWeight: 'bold', fontSize: '14px' }}>Size of the area</span>
                                <p style={{ color: '#DCE1E7', fontSize: '14px' }}>
                                    {formatNumberWithDots(data?.areaSize)} km²
                                </p>
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    height: '60px',
                                    alignItems: 'center',
                                    width: '350px',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    marginBottom: '20px',
                                    // border: '1px solid white',
                                    background: '#141617',
                                }}
                            >
                                <img
                                    src={icGridTotal}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    color: '#DCE1E7'
                                }}>Square</span>
                                <p style={{ color: '#DCE1E7', fontSize: '14px' }}>
                                    {formatNumberWithDots(data?.totalpixel)},    {data?.size}m2
                                </p>
                            </div>
                        </div>

                        {isRadiusValid && (
                            <div className="radius-slider-container" style={{ border: '1px solid #141617', backgroundColor: '#141617' }}>
                                <div className="radius-slider-wrapper">
                                    <div className="radius-slider-labels">
                                        <span>Radius</span>
                                        <span>{formatRadius(radius)}</span>
                                    </div>
                                    <Slider
                                        min={Math.min(...data.radius)}
                                        max={Math.max(...data.radius)}
                                        step={null}
                                        marks={data.radius.reduce((acc: { [x: string]: string; }, curr: string | number) => {
                                            acc[curr] = formatRadius(curr); // Convert to M or KM
                                            return acc;
                                        }, {})}
                                        value={radius}
                                        onChange={handleSliderChange}
                                        tooltipVisible={false} // Disable default tooltip
                                        className="custom-slider" />
                                    <div className="radius-slider-range">
                                        <span>{formatRadius(Math.min(...data.radius))}</span>
                                        <span>{formatRadius(Math.max(...data.radius))}</span>
                                    </div>
                                </div>
                            </div>
                        )}


                        {data?.subData.length > 0 && (
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }}>Item To Buy</span>
                        )}
                        {data?.subData.length > 0 && (
                            <div style={{
                                overflowY: 'auto',
                                scrollbarColor: 'auto',
                                borderRadius: '10px',
                                backgroundColor: '#141617',
                                height: 'calc(45vh - 50px)',
                                padding: '8px',
                            }}>
                                {data?.subData.length > 0 && (

                                    <div style={{
                                        display: 'flex',
                                        height: '50px',
                                        alignItems: 'center',
                                        border: '1px solid white',
                                        backgroundColor: 'transparent',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        marginBottom: '10px',
                                    }}>

                                        <input
                                            type="checkbox"
                                            checked={selectedItems.dataIds.some(product => product.product === data.productId) &&
                                                selectedItems.dataIds.find(item => item.product === data.productId)?.subdata.length === data?.subData.length}
                                            onChange={handleSelectAll} />
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: 'bold', marginLeft: '10px', fontSize:'14px' }}>All {data?.title}</div>
                                        </div>

                                    </div>
                                )}

                                {data?.subData?.map((item: any, index: any) => (

                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            height: '50px',
                                            alignItems: 'center',
                                            border: '1px solid white',
                                            backgroundColor: 'transparent',
                                            padding: '10px',
                                            borderRadius: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {/* Checkbox */}
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.dataIds.some(product => product.product === data.productId &&
                                                product.subdata.includes(item.subdata_id) // Check by subdata_id
                                            )}
                                            onChange={() => handleCheckboxChange(index)}
                                            style={{ marginRight: '10px' }} />

                                        {/* Content */}
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontWeight: 'bold' }}>{item.subdata}</div>
                                            <div style={{ fontWeight: 'bold', color: 'white' }}>{item?.price?.price?.toLocaleString('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                            })}</div>
                                        </div>

                                        {/* Badge */}
                                        {/* <div
                                            style={{
                                                background: 'white',
                                                padding: '5px',
                                                width: '40px',
                                                height: '20px',
                                                borderRadius: '12px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>{data.size}</Text>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* bottom box */}
                        <div style={{position:'absolute', bottom:30}}>
                        <div
                            style={{
                                border: '1px solid #141617',
                                background: '#141617',
                                paddingLeft: '15px',
                                paddingTop: '10px',
                                marginTop: '10px',
                                width: '432px',
                                height: '60px',
                                borderRadius: '12px',
                            }}
                        >
                            {/* <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> */}
                            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Item</span>
                            <p style={{ fontSize: '14px', fontStyle:'italic' }}>
                                Raw Data (.parquet, .csv, .sql, .xlsx)
                            </p>
                        </div>
                        <div
                            style={{
                                border: '1px solid #141617',
                                background: '#141617',
                                padding: '10px',
                                paddingTop: '10px',
                                marginTop: '5px',
                                width: '432px',
                                height: '80px',
                                borderRadius: '12px',
                                marginBottom: '10px',
                            }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: '12px', }}>Payment Methode</span>
                            <Select
                                defaultValue="bank"
                                style={{
                                    width: '100%',
                                    backgroundColor: '#000',    // Black background for the select body
                                    color: '#fff',              // White text color for the selected option
                                }}
                                dropdownStyle={{ padding: 8 }}
                                onChange={handleChangePaymentMethode}
                                placeholder="Select Payment Method"
                            >
                                <Option value="bank">
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <BankOutlined style={{ fontSize: '16px' }} />
                                        <span>Bank Transfer</span>
                                    </div>
                                </Option>
                                <Option
                                    disabled={dataUser?.balance < totalPrices || totalItems === undefined || totalItems === 0}
                                    value="wallet">
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {/* <span style={{ fontSize: '14px', color: '#333' }}>GeoWallet</span> */}
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <WalletOutlined style={{ fontSize: '16px' }} />
                                                <span>GeoWallet</span>
                                            </div>
                                            <span style={{ fontSize: '14px' }}>Rp {formatNumberWithDots(dataUser?.balance)}</span>
                                        </div>

                                        {dataUser?.balance < totalPrices && (
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
                                                <span style={{ color: '#ff4d4f', backgroundColor: '#fff2f0', padding: '4px 8px', borderRadius: '4px' }}>
                                                    GeoCredit Not enough Saldo. <a href="/topup" style={{ color: '#1890ff' }}>Topup here</a>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </Option>


                            </Select>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '3px' }}>
                            <div style={{ color: 'white' }}>
                                SubTotal
                            </div>
                            <div style={{ marginBottom: '5px', color: 'white' }}>
                                {totalItems}  Items for {formattedTotalPrice}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '3px' }}>
                            <Button
                                disabled={totalItems === undefined || totalItems === 0}
                                onClick={handleAddToChart}
                                id='end-point'
                                style={{
                                    width: '50px',
                                    height: '48px',
                                    backgroundColor: 'transparent',
                                    color: '#316DBA',
                                    display: 'flex',
                                    borderColor: 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transition: 'transform 0.3s ease', // Smooth transition
                                }}
                                onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                            >
                                <img
                                    src={icChart}
                                    alt="Button Icon"
                                    width={30}
                                    height={30} />
                            </Button>
                            {/* <Button
                                    disabled={totalItems === undefined || totalItems === 0}
                                    onClick={handleOpenWallet}
                                    id='end-point'
                                    style={{
                                        // width: '50px',
                                        height: '48px',
                                        backgroundColor: 'transparent',
                                        color: '#316DBA',
                                        display: 'flex',
                                        borderColor: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        transition: 'transform 0.3s ease', // Smooth transition
                                    }}
                                    onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                    onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                                >
                                    <LiaCoinsSolid size={30} style={{ color: 'white', fontSize: '20px' }} />
                                </Button> */}
                            {loading ? (
                                <Button type="primary"
                                    style={{
                                        height: '48px',
                                        width: '100%',
                                        color: 'white',
                                        backgroundColor: '#4A7FC0',
                                    }}>
                                    <Spin indicator={<LoadingOutlined spin />} size="small" style={{ color: 'white' }} />
                                </Button>
                            ) : (
                                <Button
                                    onClick={
                                        selectedPaymentMethod === 'wallet'
                                            ? handleOpenWallet
                                            : handleSubmit
                                    }
                                    disabled={totalItems === undefined || totalItems === 0}
                                    style={{
                                        height: '48px',
                                        width: '100%',
                                        color: 'white',
                                        backgroundColor: totalItems === undefined || totalItems === 0 ? 'gray' : '#4A7FC0',
                                        borderColor: totalItems === undefined || totalItems === 0 ? 'gray' : '#4A7FC0',
                                        transition: 'transform 0.3s ease', // Smooth transition
                                    }}
                                    onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                                    onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}
                                >
                                    Checkout
                                </Button>

                            )}

                        </div>
                        </div>
                       
                    </div>
                </div>
            </Modal>
            <ModalWalletMethode
                isModalConfirmPayment={modalWalletPayment}
                data={paymentCheckout}
                amount={totalPrices}
            />
            <ModalLogin
                visible={isLoginModalVisible}
            />
        </>
    )
}
