import React from 'react';
import { Card, Button } from 'antd';
import { FaRegClock } from "react-icons/fa";
import Text from '../../../../style/styledComponent/Text';
import icAreaSize from '../../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icChart from '../../../../asesst/Icons/interactivemap/icon/cart.svg';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import { formatNumberWithDots } from '../../../../component/newmap/helper/formatNumber';
import { ModalDefaultMobile } from '../../modal/modal-checkout-mobile.tsx/modal-checkout-mobile';

interface InsightCardProps {
  id: string;
  polygonId: string;
  readiness: string;
  subData: any;
  version: any;
  title: string;
  productId: string;
  carPrice: string;
  description: string;
  price: string;
  size: any;
  totalpixel: any;
  status: string;
  output: any;
  year: number;
  areaSize: any;
  address: any;
  radius: any;
  centeroid: {
    lattitude: number;
    longitude: number;
  };
}

interface CardListProps {
  dataCard: InsightCardProps[];
}

export const MobileCardListCatalog: React.FC<CardListProps> = ({ dataCard }) => {
  const { 
    setModalOpenCheckout,
    setSelectedItem,
    isModalCheckout, 
    selectedItem,
  } = useReduxCatalog();

  const showModal = (item: InsightCardProps) => {
    setSelectedItem(item);
    setModalOpenCheckout(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        overflowX: 'auto',
        maxHeight:'200px',
        maxWidth: '100vw', // Ensure it doesn't exceed the viewport width
        flexWrap: 'nowrap', // Prevent cards from wrapping to the next line
      }}
    >
      {dataCard.map((item, index) => (
        <div
          key={index}
          className="custom-card-mobile"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <Card
            style={{
              width: 280,
              backgroundColor: '#142943',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', marginBottom: 16 }}>
              <div>
                <Text style={{ color: 'white', margin: 0, fontSize: '16px', fontWeight: 'bold', paddingRight: '10px' }}>{item.title}</Text>
            
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginTop: '5px',
                    marginBottom: '10px',
                }}
                >
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <img alt="Area Size" src={icAreaSize} style={{ width: '16px', height: '16px' }} />
                    <Text style={{ color: 'white', margin: 0, fontSize: '12px', width:'90px'}}>
                    {formatNumberWithDots(item.areaSize)} m²
                    </Text>
                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <img alt="Grid Total" src={icGridTotal} style={{ width: '16px', height: '16px' }} />
                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>
                    {formatNumberWithDots(item.totalpixel)}
                    </Text>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <FaRegClock style={{ color: 'white', fontSize: '16px' }} />
                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>
                    {item.year || '-'}
                    </Text>
                </div>
                </div>

              
                <Text style={{ color: 'white', margin: 0, fontSize: '14px' }}>Start From</Text>
                <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0 }}>{item.carPrice}</h3>
              </div>
              <div
                style={{
                  background: 'white',
                  position:'absolute',
                  right:'20px',
                  padding: '5px',
                  width: '60px',
                  height: '20px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>{item.size}</Text>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '3px' }}>
              <Button
                onClick={() => showModal(item)}
                style={{
                  backgroundColor: 'transparent',
                  color: '#316DBA',
                  display: 'flex',
                  height: '40px',
                  width: '42px',
                  borderColor: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={icChart} alt="Button Icon" width={20} height={20} />
              </Button>
              <Button 
                style={{
                  borderRadius: '8px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  borderColor: 'white',
                  height: '40px',
                  width: '100px',
                }}
                onClick={() => showModal(item)}
              >
                View Detail
              </Button>
              <Button
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#4A7FC0',
                  borderColor: 'transparent',
                  color: 'white',
                  height: '40px',
                  width: '100px',
                }}
                onClick={() => showModal(item)}
              >
                {item.price === 'Free' ? 'Get Data' : 'Buy Data'}
              </Button>
            </div>
          </Card>
        </div>
      ))}
      <ModalDefaultMobile isModal={isModalCheckout} data={selectedItem} />
    </div>
  );
};
