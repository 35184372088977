import React from 'react';
import { Card, Button, notification } from 'antd';
import { FaRegClock } from "react-icons/fa";
import Text from '../../../../style/styledComponent/Text';
// import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import { formatDate } from '../../../../component/newmap/helper/date';
import { formatNumberWithDots } from '../../../../component/newmap/helper/formatNumber';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';

interface MyDataCardProps {
    address: string;
    alias: string;
    centeroid: number[];
    created_at: string;
    total_grid: number;
    metadata_location: any;
    polygon_id: string;
    product: string;
    size_grid: number;
    status: string;
    table_location: any;
    table_ready: boolean;
    description: string;
}

interface CardListProps {
    dataCard: MyDataCardProps[]; // Array of cards to be displayed
}

export const CardListMyDataMobile: React.FC<CardListProps> = ({ dataCard }) => {

    const {
        setMetaData,
    } = useReduxCatalog();

    const handleSeeData = (item: any) => {
        if (item?.status !== 'SUCCESS') {
            notification.warning({
                style: {
                    backgroundColor: '#142943',
                    color: 'white'  // Menjadikan teks berwarna putih
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>{item?.status}</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Your data is currently being processed. Please wait or proceed with payment if you haven’t done so yet.</p>,
                placement: 'topRight',
            });
        } else {
            // Aksi untuk view detail atau see data
            setMetaData(item)
            // console.log(item);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                overflowX: 'auto',
                maxHeight: '200px',
                maxWidth: '100vw', // Ensure it doesn't exceed the viewport width
                flexWrap: 'nowrap', // Prevent cards from wrapping to the next line
            }}
        >
            {dataCard?.map((item, index) => (
                <div
                    key={index}
                    className="custom-card"
                    style={{
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease', // Smooth transition
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'translateX(10px)'; // Move to the right by 10px
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'translateX(0)'; // Reset position
                    }}
                >
                    <Card
                        style={{
                            width: 280,
                            backgroundColor: '#142943',
                            color: 'white',
                            cursor: 'pointer',
                        }}
                    >
                        <div style={{ display: 'flex', width: '300px', marginLeft: '10px' }}>
                            <div style={{ width: '250px' }}>
                                <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', }}>
                                    {item?.alias || 'No Data'}
                                    <div
                                        style={{
                                            background: 'white',
                                            padding: '5px',
                                            height: '20px',
                                            borderRadius: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text style={{ fontWeight: 'bold', color: '#316DBA', margin: 0, fontSize: '10px' }}>{item?.size_grid < 50 ? '5x5' : '50x50'}</Text>
                                    </div>
                                </div>
                                <Text style={{ fontWeight: 'bold', color: 'lightgray', margin: 0, fontSize: '12px' }}>{item?.address || '-'}</Text>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                                    <img
                                        alt="example"
                                        src={icGridTotal}
                                        style={{ display: 'block' }} // Pastikan gambar sejajar dengan teks
                                    />
                                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>
                                        {formatNumberWithDots(item?.total_grid)}
                                    </Text>

                                    <FaRegClock style={{ color: 'white', verticalAlign: 'middle' }} />
                                    <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>
                                        {formatDate(item.created_at)}
                                    </Text>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'left', gap: '10px', marginBottom: '5px', marginTop: '3px' }}>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', cursor: 'pointer', justifyContent: 'space-evenly', gap: '3px' }}>
                            <Button
                                onClick={() => handleSeeData(item)}
                                style={{
                                    borderRadius: '8px',
                                    cursor: 'pointer', // Ganti 'default' dengan 'pointer' di sini
                                    color: 'white',
                                    backgroundColor: item?.status === 'SUCCESS' ? '#4A7FC0' : '#E97451',
                                    borderColor: 'black',
                                    height: '40px',
                                    width: '100%'
                                }}
                            >
                                <Text style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                    {item?.status === 'SUCCESS' ? 'See Data' : item?.status}
                                </Text>
                            </Button>
                        </div>

                    </Card>
                </div>
            ))}
        </div>
    );
};
